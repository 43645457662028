import { trackCustomEvent } from "gatsby-plugin-google-analytics";

export function gaEvent(category: string) {
  trackCustomEvent({
    category: category,
    action: "none",
    label: "none",
    value: 0
  });
}
