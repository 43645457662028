export class SearchResult {
  public chapterNumber: number;
  public verseNumber: number;
  public text: string;
  public wasClicked: boolean;
  public direction: string;
  public languageCode: string;
  public translationCode: string;

  public constructor(
    chapterNumber: number,
    verseNumber: number,
    text: string,
    wasClicked: boolean,
    direction: string,
    languageCode: string,
    translationCode: string
  ) {
    this.chapterNumber = chapterNumber;
    this.verseNumber = verseNumber;
    this.text = text;
    this.wasClicked = wasClicked;
    this.direction = direction;
    this.languageCode = languageCode;
    this.translationCode = translationCode;
  }
}
