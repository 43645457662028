// https://stackoverflow.com/questions/47879184/document-execcommandcopy-not-working-on-chrome

export function copyToClipboard(str: string) {
  var textarea = document.createElement("textarea");
  textarea.textContent = str;
  document.body.appendChild(textarea);

  var selection = document.getSelection();
  var range = document.createRange();
  //  range.selectNodeContents(textarea);
  range.selectNode(textarea);
  selection.removeAllRanges();
  selection.addRange(range);

  document.execCommand("copy");
  selection.removeAllRanges();

  document.body.removeChild(textarea);
}
