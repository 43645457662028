import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { websiteUrl } from "../constants/BaseConstants";
import { Chapter } from "../models/Chapter";
import { ToHindi } from "../common/ToHindi";
import { computeUrlPath } from "../common/ComputeUrlPath";
import { TEXTBOOKS } from "../constants/Textbooks";
import { gaEvent } from "../common/GaEvent";
import { copyToClipboard } from "../common/CopyToClipboard";
import CloseIcon from "@material-ui/icons/Close";
import { arraysEqual } from "../common/ArraysEqual";

const useStyles = makeStyles(() => ({
  dialog: {},
  appBar: {
    position: "relative"
  },
  closeButton: {
    position: "absolute",
    right: "8px",
    top: "10px",
    color: "#909090"
  },
  content: {
    marginTop: "20px",
    marginBottom: "25px",
    marginLeft: "30px",
    marginRight: "30px",
    padding: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  formControl: {
    width: "100px",
    margin: "0px",
    marginBottom: "30px"
  },
  select: {},
  shareButton: {
    width: "100px",
    marginTop: "5px"
  }
}));

interface ShareVersesDialogProps {
  currentVerseNumber: number;
  chapter: Chapter;
  isOpen: boolean;
  isShare: boolean;
  handleCloseDialog: Function;
  textBooks: string[];
  defaultTextBooks: string[];
  recitations: string[];
  defaultRecitations: string[];
}

const arePropsEqual = (prevProps: ShareVersesDialogProps, nextProps: ShareVersesDialogProps) => {
  const areEqual =
    prevProps.currentVerseNumber === nextProps.currentVerseNumber &&
    prevProps.chapter.chapterNumber === nextProps.chapter.chapterNumber &&
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.isShare === nextProps.isShare &&
    arraysEqual(prevProps.textBooks, nextProps.textBooks) &&
    arraysEqual(prevProps.recitations, nextProps.recitations);
  return areEqual;
};

function ShareVersesDialog(props: ShareVersesDialogProps) {
  const [fromVerseNumber, setFromVerseNumber] = React.useState(props.currentVerseNumber);
  const [toVerseNumber, setToVerseNumber] = React.useState(props.currentVerseNumber);

  const classes = useStyles(props);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleFromVerseChange(event: any) {
    setFromVerseNumber(event.target.value);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleToVerseChange(event: any) {
    setToVerseNumber(event.target.value);
  }

  function onClickShareButton() {
    let min = Math.min(fromVerseNumber, toVerseNumber);
    let max = Math.max(fromVerseNumber, toVerseNumber);
    let text = "";

    // Loop through all textBooks
    for (let i = 0; i < props.textBooks.length; i++) {
      if (props.textBooks[i] == "ar-allah") {
        const minString = min === 0 ? "" : ToHindi(min, "ar");
        let verseIndex = props.chapter.verseNumbers.indexOf(min);
        let verse = props.chapter.verses[i][verseIndex];
        text = `﴿${ToHindi(props.chapter.chapterNumber, "ar")} ${props.chapter.nameArabic} ${minString}﴾ ${verse}`;
        for (let j = min + 1; j <= max; j++) {
          let verseIndex = props.chapter.verseNumbers.indexOf(j);
          let verse = props.chapter.verses[i][verseIndex];
          text = `${text}\r\n﴿${ToHindi(j, "ar")}﴾ ${verse}`;
        }
        text = `${text}\r\n\r\n`;
      } else {
        const textBook = TEXTBOOKS.filter(item => item.translationCode === props.textBooks[i])[0];
        const minString = min === 0 ? "" : ToHindi(min, textBook.languageCode);
        const colon = min === 0 ? "" : ":";
        let verseIndex = props.chapter.verseNumbers.indexOf(min);
        let verse = props.chapter.verses[i][verseIndex];
        let chapterVerseNumbers: string;
        if (textBook.direction === "ltr") {
          chapterVerseNumbers = `${ToHindi(props.chapter.chapterNumber, textBook.languageCode)}${colon}${minString}`;
        } else {
          chapterVerseNumbers = `${minString}${colon}${ToHindi(props.chapter.chapterNumber, textBook.languageCode)}`;
        }
        text = `${text}[${chapterVerseNumbers} ${textBook.translationName}] ${verse}`;
        for (let j = min + 1; j <= max; j++) {
          let verseIndex = props.chapter.verseNumbers.indexOf(j);
          let verse = props.chapter.verses[i][verseIndex];
          text = `${text}\r\n[${ToHindi(j, textBook.languageCode)}] ${verse}`;
        }
        text = `${text}\r\n\r\n`;
      }
    }

    let url = websiteUrl;

    const urlHash = computeUrlPath(
      min,
      props.chapter.chapterNumber,
      props.defaultTextBooks,
      props.textBooks,
      props.defaultRecitations,
      props.recitations
    );

    if (urlHash) {
      url = `${url}${urlHash}`;
    }

    if (props.isShare) {
      // @ts-ignore
      navigator.share({
        title: "Quran",
        text: text,
        url: url
      });
      props.handleCloseDialog("");
      gaEvent("Verses Shared");
    } else {
      copyToClipboard(`${text}${url}`);
      //navigator.clipboard.writeText(`${text}${url}`);
      props.handleCloseDialog("Verses Copied to Clipboard");
      gaEvent("Verses Copied to Clipboard");
    }
  }

  React.useEffect(() => {
    setFromVerseNumber(props.currentVerseNumber);
    setToVerseNumber(props.currentVerseNumber);
  }, [props.currentVerseNumber, props.chapter]);

  return (
    <Dialog
      onClose={() => props.handleCloseDialog("")}
      aria-labelledby="customized-dialog-title"
      open={props.isOpen}
      className={classes.dialog}
    >
      <DialogTitle>
        <IconButton aria-label="close" className={classes.closeButton} onClick={() => props.handleCloseDialog("")}>
          <CloseIcon />
        </IconButton>
        {props.isShare ? "Share Verses" : " Copy Verses"}
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.content}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="from-verse">From Verse</InputLabel>
            <Select
              value={fromVerseNumber}
              onChange={event => handleFromVerseChange(event)}
              inputProps={{
                name: "from-selector",
                id: "from-selector"
              }}
              className={classes.select}
            >
              {props.chapter.verseNumbers.map((item: number) => (
                <MenuItem value={item} key={"from" + item.toString()} id={"from" + item.toString()}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="from-verse">To Verse</InputLabel>
            <Select
              value={toVerseNumber}
              onChange={event => handleToVerseChange(event)}
              inputProps={{
                name: "to-selector",
                id: "to-selector"
              }}
              className={classes.select}
            >
              {props.chapter.verseNumbers.map((item: number) => (
                <MenuItem value={item} key={"from" + item.toString()} id={"from" + item.toString()}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <a id="share-verses-button">
            <Button variant="contained" color="primary" className={classes.shareButton} type="submit" onClick={onClickShareButton}>
              &nbsp; {props.isShare ? "Share" : " Copy"}&nbsp;
            </Button>
          </a>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(ShareVersesDialog, arePropsEqual);
