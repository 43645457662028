import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { fontScalings } from "../constants/FontScalings";

const useStyles = makeStyles(() => ({
  menuTitle: {
    padding: "10px 00px 5px 15px",
    fontWeight: "bold",
    fontSize: "16px",
    width: "125px"
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

interface FontScalingMenuProps {
  anchor: HTMLElement;
  fontScaling: number;
  handleMenuClose: Function;
  fontScalingItemClicked: Function;
}

function FontScalingMenu(props: FontScalingMenuProps) {
  const classes = useStyles(props);

  return (
    <Menu id="quran-fonts-menu" anchorEl={props.anchor} open={Boolean(props.anchor)} onClose={() => props.handleMenuClose(null)}>
      <Typography className={classes.menuTitle}>Text Scaling</Typography>
      {fontScalings.map((item: number) => (
        <MenuItem
          key={"font-scaling-item" + item}
          className={classes.menuItem}
          selected={props.fontScaling === item}
          onClick={() => props.fontScalingItemClicked(item)}
        >
          {item}%
        </MenuItem>
      ))}
    </Menu>
  );
}
export default FontScalingMenu;
