import React from "react";
import { Chapter } from "../models/Chapter";
import { RECITATIONS } from "../constants/Recitations";
// @ts-ignore
import ReactAudioPlayer from "react-audio-player";

interface AudioComponentProps {
  recitations: string[];
  recitationPointer: number;
  handleRecitationPointerChange: Function;
  chapter: Chapter;
  verseNumber: number;
  isAudioPlaying: boolean;
  isAudioPlaying2: boolean;
  audioEnded: Function;
}

function AudioComponent(props: AudioComponentProps) {
  const playerA = React.useRef(null);
  const playerB = React.useRef(null);

  function getFileNumberWithLeadingZeros(num: number) {
    return ("00" + num).slice(-3);
  }

  function getVolume(recitationCode: string) {
    return RECITATIONS.filter(recitation => recitation.code === recitationCode)[0].scaling;
  }

  function getUrl(recitationCode: string, vNumber: number) {
    const recitation = RECITATIONS.filter(recitation => recitation.code === recitationCode)[0];
    let url: string;
    if (vNumber === 0 && recitation.bismiSingle) {
      url = `${recitation.baseUrl}001${recitation.seperator}001.mp3`;
    } else {
      url = `${recitation.baseUrl}${getFileNumberWithLeadingZeros(props.chapter.chapterNumber)}${
        recitation.seperator
      }${getFileNumberWithLeadingZeros(vNumber)}.mp3`;
    }
    return url;
  }

  // when enough audio data is loaded, load next file (for caching)
  React.useEffect(() => {
    playerA.current.audioEl.oncanplaythrough = function() {
      const currentAudioUrl = playerA.current.audioEl.src;
      let nextVerseNumber = getFileNumberWithLeadingZeros(
        +currentAudioUrl.substring(currentAudioUrl.length - 7, currentAudioUrl.length - 4) + 1
      );
      let nextAudioUrl =
        currentAudioUrl.substring(0, currentAudioUrl.length - 7) +
        nextVerseNumber +
        currentAudioUrl.substring(currentAudioUrl.length, currentAudioUrl.length - 4);
      playerB.current.audioEl.src = nextAudioUrl;
    };
  }, []);

  function handleAudioTrackEnded(event: any) {
    if (props.recitationPointer >= props.recitations.length - 1) {
      props.audioEnded();
    } else {
      props.handleRecitationPointerChange(props.recitationPointer + 1);
    }
  }

  React.useEffect(() => {
    if (!props.isAudioPlaying || !props.isAudioPlaying2) {
      playerA.current.audioEl.pause();
    }
  }, [props.isAudioPlaying, props.isAudioPlaying2]);

  return (
    <div>
      <ReactAudioPlayer
        src={props.isAudioPlaying && props.isAudioPlaying2 ? getUrl(props.recitations[props.recitationPointer], props.verseNumber) : null}
        autoPlay={props.isAudioPlaying && props.isAudioPlaying2}
        onEnded={handleAudioTrackEnded}
        muted={!props.isAudioPlaying || !props.isAudioPlaying2} // keep this - somehow, on iPhone 5, things do not work without it
        ref={playerA}
        volume={getVolume(props.recitations[props.recitationPointer])}
      />
      <ReactAudioPlayer autoPlay={false} muted={true} ref={playerB} />
    </div>
  );
}

export default AudioComponent;
