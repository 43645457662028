import { TextTheme } from "../models/TextTheme";

export const textThemes: TextTheme[] = [
  {
    background: "#ffffff",
    verseSelectedBackground: "#f6fef6",
    verseSelectedBorder: "#BDFCC9",
    text: "#222",
    borderBottomLight: "#dfdfdf",
    titles: "#115293",
    translationName: "#606060",
    verseNumber: "#585858",
    verseNumberBackground: "#eeeeee"
  },
  {
    background: "#101010",
    verseSelectedBackground: "#1e1e1e",
    verseSelectedBorder: "#777777",
    text: "#e0e0e0",
    borderBottomLight: "#282828",
    titles: "#4485C6",
    translationName: "#a0a0a0",
    verseNumber: "#d0d0d0",
    verseNumberBackground: "#383838"
  }
];
