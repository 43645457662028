import { TEXTBOOKS } from "../constants/Textbooks";
import { RECITATIONS } from "../constants/Recitations";

export function getTextBooksFromUrl(url: string): string[] {
  let items: string[] = [];

  let pieces = url.split("/");
  let itemNumber = 1;
  for (let i = 0; i < pieces.length; i++) {
    if (pieces[i] === `t${itemNumber.toString()}`) {
      if (TEXTBOOKS.filter(book => book.translationCode === pieces[i + 1])[0].translationCode) {
        items.push(pieces[i + 1]);
        i = i + 1;
        itemNumber = itemNumber + 1;
      }
    }
  }
  return items;
}

export function getRecitationsFromUrl(url: string): string[] {
  let items: string[] = [];

  let pieces = url.split("/");
  let itemNumber = 1;
  for (let i = 0; i < pieces.length; i++) {
    if (pieces[i] === `a${itemNumber.toString()}`) {
      if (RECITATIONS.filter(recitation => recitation.code === pieces[i + 1])[0].code) {
        items.push(pieces[i + 1]);
        i = i + 1;
        itemNumber = itemNumber + 1;
      }
    }
  }
  return items;
}

export function getChapterNumberFromUrl(url: string): number {
  let pieces = url.split("/");
  for (let i = 0; i < pieces.length; i++) {
    if (pieces[i] === `ch` && +pieces[i + 1] > 0 && +pieces[i + 1] < 115) {
      return +pieces[i + 1];
    }
  }
  return -1;
}

export function getVerseNumberFromUrl(url: string): number {
  let pieces = url.split("/");
  for (let i = 0; i < pieces.length; i++) {
    if (pieces[i] === `v` && +pieces[i + 1] > 0 && +pieces[i + 1] < 287) {
      return +pieces[i + 1];
    }
  }
  return -1;
}
