import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import { ChapterInfo } from "../models/ChapterInfo";

const useStyles = makeStyles({
  menuItem: {
    paddingRight: 0
  },
  chapterNumber: {
    width: "30px",
    textAlign: "right"
  },
  chapterNameArabic: {
    width: "65px",
    textAlign: "right",
    fontFamily: "Nashk"
  },
  chapterNameTrans: {
    width: "115px"
  },
  horizontalSpacer: {
    width: "25px"
  }
});

interface ChaptersMenuProps {
  anchor: HTMLElement;
  chaptersInfo: ChapterInfo[];
  chapterClicked: Function;
  handleMenuClose: Function;
  currentChapterNumber: number;
}

const arePropsEqual = (prevProps: ChaptersMenuProps, nextProps: ChaptersMenuProps) => {
  const areEqual = prevProps.anchor === nextProps.anchor && prevProps.currentChapterNumber === nextProps.currentChapterNumber;
  return areEqual;
};

function ChaptersMenu(props: ChaptersMenuProps) {
  const classes = useStyles(props);
  return (
    <Menu anchorEl={props.anchor} open={Boolean(props.anchor)} onClose={() => props.handleMenuClose(null)}>
      <Box m={1} />
      {props.chaptersInfo.map((item: ChapterInfo, index: number) => (
        <MenuItem
          key={"chapter-menu" + index}
          selected={props.currentChapterNumber === index + 1}
          onClick={() => props.chapterClicked(index)}
          className={classes.menuItem}
        >
          <span className={classes.chapterNumber}>{index + 1} </span>
          <span className={classes.horizontalSpacer} />
          <span className={classes.chapterNameArabic}>{item.nameArabic} </span>
          <span className={classes.horizontalSpacer} />
          <span className={classes.chapterNameTrans}>{item.nameTrans} </span>
        </MenuItem>
      ))}
      <Box m={1} />
    </Menu>
  );
}

export default React.memo(ChaptersMenu, arePropsEqual);
