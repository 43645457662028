import { arraysEqual } from "./ArraysEqual";

export function computeUrlPath(
  verseNumber: number,
  chapterNumber: number,
  defaultTextBooks: string[],
  textBooks: string[],
  defaultRecitations: string[],
  recitations: string[]
) {
  let versePart = "";
  let chapterPart = "";
  let textBooksPart = "";
  let recitationsPart = "";
  let hash: string;

  if (chapterNumber === 1 || chapterNumber === 9) {
    if (verseNumber > 1) {
      versePart = `/v/${verseNumber}`;
    }
  } else {
    if (verseNumber > 0) {
      versePart = `/v/${verseNumber}`;
    }
  }
  if (chapterNumber > 1) {
    chapterPart = `/ch/${chapterNumber}`;
  }
  if (!arraysEqual(defaultTextBooks, textBooks)) {
    for (let i = 0; i < textBooks.length; i++) {
      textBooksPart = `${textBooksPart}/t${i + 1}/${textBooks[i]}`;
    }
  }
  if (!arraysEqual(defaultRecitations, recitations)) {
    for (let i = 0; i < recitations.length; i++) {
      recitationsPart = `${recitationsPart}/a${i + 1}/${recitations[i]}`;
    }
  }
  if (chapterPart || textBooksPart || recitationsPart || versePart) {
    hash = `#${chapterPart}${textBooksPart}${recitationsPart}${versePart}`;
  } else {
    hash = " "; // This should be space - do not remove
  }
  return hash;
}
