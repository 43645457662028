import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import SettingsIcon from "@material-ui/icons/Settings";
import SwapVert from "@material-ui/icons/SwapVert";
import FormatSize from "@material-ui/icons/FormatSize";
import MenuIcon from "@material-ui/icons/Menu";
import { iconColor } from "../constants/BaseConstants";
import { Chapter } from "../models/Chapter";
import ButtonBase from "@material-ui/core/ButtonBase";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import "../css/global-styles.css";
import { topAppBarHeight } from "../constants/BaseConstants";

const titleBlockWidth = "180px";
const titleBlockWidthNarrow = "160px";

const useStyles = makeStyles({
  appBar: {
    borderTop: "1px solid #bbbbbb"
  },
  appBarContents: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  icon: {
    color: iconColor,
    padding: "4px",
    ["@media (min-width:360px)"]: {
      padding: "5px"
    },
    ["@media (min-width:560px)"]: {
      padding: "6px"
    }
  },
  titleBlock: {
    width: titleBlockWidthNarrow,
    ["@media (min-width:360px)"]: {
      width: titleBlockWidth
    },
    margin: "0px 0px 0px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderLeft: "1px solid #bbbbbb",
    borderRight: "1px solid #bbbbbb",
    height: topAppBarHeight
  },
  titleBlockRow1: {
    lineHeight: "1.0",
    display: "flex",
    flexDirection: "row"
  },
  titleBlockRow2: {
    lineHeight: "1.0"
  },
  nameArabic: {
    color: "#eeeeee",
    fontFamily: "Nashk",
    fontSize: "18px",
    lineHeight: "1.2"
  },
  nameTrans: {
    color: "#eeeeee",
    fontFamily: "Roboto",
    fontSize: "18px",
    lineHeight: "1.2"
  },
  number: {
    position: "absolute",
    left: "10px",
    color: "#eeeeee",
    fontFamily: "Roboto",
    fontSize: "15px",
    lineHeight: "1.4"
  },
  chevron: {
    position: "absolute",
    right: "10px",
    color: "#ff9800",
    lineHeight: "1.4"
  },
  separator: {
    width: "0px",
    ["@media (min-width:400px)"]: {
      width: "10px"
    },
    ["@media (min-width:440px)"]: {
      width: "20px"
    }
  }
});

function HideOnScroll(props: any) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

interface TopAppBarProps {
  chapter: Chapter;
  onClickMenuButton: (event: React.MouseEvent<HTMLElement>) => void;
  onClickTitleBlock: (event: React.MouseEvent<HTMLElement>) => void;
  onClickVerticalSwapButton: () => void;
  onClickSettingsButton: () => void;
  onClickTextSizeButton: (event: React.MouseEvent<HTMLElement>) => void;
}

const arePropsEqual = (prevProps: TopAppBarProps, nextProps: TopAppBarProps) => {
  return prevProps.chapter.chapterNumber === nextProps.chapter.chapterNumber;
};

function TopAppBar(props: TopAppBarProps) {
  const classes = useStyles(props);

  return (
    <HideOnScroll {...props}>
      <AppBar className={classes.appBar}>
        <div className={classes.appBarContents}>
          {/* Menu Button */}
          <IconButton aria-label="Menu" title="Menu" onClick={props.onClickMenuButton} className={classes.icon}>
            <MenuIcon className={classes.icon} />
          </IconButton>

          <IconButton aria-label="Text Scaling" title="Text Scaling" onClick={props.onClickTextSizeButton} className={classes.icon}>
            <FormatSize className={classes.icon} />
          </IconButton>

          <div className={classes.separator}></div>

          <ButtonBase onClick={props.onClickTitleBlock} title="Select Sura/Chapter">
            <div className={classes.titleBlock}>
              <div className={classes.titleBlockRow1}>
                <div className={classes.number}>{props.chapter.chapterNumber}</div>
                <div className={classes.nameArabic}>{props.chapter.nameArabic}</div>
                <div className={classes.chevron}>▼</div>
              </div>
              <div className={classes.titleBlockRow2}>
                <div className={classes.nameTrans}>{props.chapter.nameTrans}</div>
              </div>
            </div>
          </ButtonBase>

          <div className={classes.separator}></div>

          {/* Go To Verse Button */}
          <IconButton aria-label="Go to Verse" title="Go to Verse" onClick={props.onClickVerticalSwapButton} className={classes.icon}>
            <SwapVert className={classes.icon} />
          </IconButton>

          {/* Settings Button */}
          <IconButton aria-label="Settings" title="Settings" onClick={props.onClickSettingsButton} className={classes.icon}>
            <SettingsIcon className={classes.icon} />
          </IconButton>
        </div>
      </AppBar>
    </HideOnScroll>
  );
}

export default React.memo(TopAppBar, arePropsEqual);
