import { TextBook } from "../models/TextBook";

export const TEXTBOOKS: TextBook[] = [
  {
    language: "Arabic",
    translationName: "القرآن الكريم - Quran",
    translator: "Allah",
    languageCode: "ar",
    translationCode: "ar-allah",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Arabic",
    translationName: "تفسير الجلالين",
    translator: "Jalal ad-Din al-Mahalli and Jalal ad-Din as-Suyuti",
    languageCode: "ar",
    translationCode: "ar-jalalayn",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Arabic",
    translationName: "تفسير المیسر",
    translator: "King Fahad Quran Complex",
    languageCode: "ar",
    translationCode: "ar-muyassar",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Albanian",
    translationName: "Efendi Nahi",
    translator: "Hasan Efendi Nahi",
    languageCode: "sq",
    translationCode: "sq-nahi",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Albanian",
    translationName: "Feti Mehdiu",
    translator: "Feti Mehdiu",
    languageCode: "sq",
    translationCode: "sq-mehdiu",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Albanian",
    translationName: "Sherif Ahmeti",
    translator: "Sherif Ahmeti",
    languageCode: "sq",
    translationCode: "sq-ahmeti",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Amazigh",
    translationName: "At Mensur",
    translator: "Ramdane At Mansour",
    languageCode: "be",
    translationCode: "ber-mensur",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Amharic ",
    translationName: "ሳዲቅ & ሳኒ ሐቢብ",
    translator: "Muhammed Sadiq and Muhammed Sani Habib",
    languageCode: "am",
    translationCode: "am-sadiq",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Azerbaijani",
    translationName: "Məmmədəliyev & Bünyadov",
    translator: "Vasim Mammadaliyev and Ziya Bunyadov&nbsp;&#x2020;",
    languageCode: "az",
    translationCode: "az-mammadaliyev",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Azerbaijani",
    translationName: "Musayev",
    translator: "Alikhan Musayev",
    languageCode: "az",
    translationCode: "az-musayev",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Bengali",
    translationName: "জহুরুল হক",
    translator: "Zohurul Hoque",
    languageCode: "bn",
    translationCode: "bn-hoque",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Bengali",
    translationName: "মুহিউদ্দীন খান",
    translator: "Muhiuddin Khan",
    languageCode: "bn",
    translationCode: "bn-bengali",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Bosnian",
    translationName: "Korkut",
    translator: "Besim Korkut",
    languageCode: "bs",
    translationCode: "bs-korkut",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Bosnian",
    translationName: "Mlivo",
    translator: "Mustafa Mlivo",
    languageCode: "bs",
    translationCode: "bs-mlivo",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Bulgarian",
    translationName: "Теофанов",
    translator: "Tzvetan Theophanov",
    languageCode: "bg",
    translationCode: "bg-theophanov",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Chinese",
    translationName: "Ma Jian - Simplified",
    translator: "Ma Jian",
    languageCode: "zh_Hans",
    translationCode: "zh-jian",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Chinese",
    translationName: "Ma Jian - Traditional",
    translator: "Ma Jian",
    languageCode: "zh_Hant",
    translationCode: "zh-majian",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Czech",
    translationName: "Hrbek",
    translator: "Preklad I. Hrbek",
    languageCode: "cs",
    translationCode: "cs-hrbek",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Czech",
    translationName: "Nykl",
    translator: "A. R. Nykl",
    languageCode: "cs",
    translationCode: "cs-nykl",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Divehi",
    translationName: "ދިވެހި",
    translator: "Office of the President of Maldives",
    languageCode: "dv",
    translationCode: "dv-divehi",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Dutch",
    translationName: "Keyzer",
    translator: "Salomo Keyzer ",
    languageCode: "nl",
    translationCode: "nl-keyzer",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Dutch",
    translationName: "Leemhuis",
    translator: "Fred Leemhuis",
    languageCode: "nl",
    translationCode: "nl-leemhuis",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Dutch",
    translationName: "Siregar",
    translator: "Sofian S. Siregar",
    languageCode: "nl",
    translationCode: "nl-siregar",
    direction: "ltr",
    isSearchable: true
  },
  /*   {
    language: "English",
    translationName: "Abdel Haleem",
    translator: "M.A.S. Abdel Haleem",
    languageCode: "en",
    translationCode: "en-abdelhaleem",
    direction: "ltr",
	isSearchable: true
  }, */
  {
    language: "English",
    translationName: "Ahmed Ali",
    translator: "Ahmed Ali",
    languageCode: "en",
    translationCode: "en-ahmedali",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "Ahmed Raza Khan",
    translator: "Ahmed Raza Khan",
    languageCode: "en",
    translationCode: "en-ahmedraza",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "Arberry",
    translator: "A. J. Arberry",
    languageCode: "en",
    translationCode: "en-arberry",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "Asad",
    translator: "Muhammad Asad",
    languageCode: "en",
    translationCode: "en-asad",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "Community - Allah",
    translator: "Talal Itani & Community",
    languageCode: "en",
    translationCode: "en-itaniv2a",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "Community - God",
    translator: "Talal Itani & Community",
    languageCode: "en",
    translationCode: "en-itaniv2",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "ClearQuran - Allah",
    translator: "Talal Itani",
    languageCode: "en",
    translationCode: "en-itania",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "ClearQuran - God",
    translator: "Talal Itani",
    languageCode: "en",
    translationCode: "en-itani",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "Daryabadi",
    translator: "Abdul Majid Daryabadi",
    languageCode: "en",
    translationCode: "en-daryabadi",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "Hilali & Khan",
    translator: "Muhammad Taqi-ud-Din al-Hilali and Muhammad Muhsin Khan",
    languageCode: "en",
    translationCode: "en-hilali",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "Khattab",
    translator: "Mustafa Khattab",
    languageCode: "en",
    translationCode: "en-khattab",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "Maududi",
    translator: "Abul Ala Maududi",
    languageCode: "en",
    translationCode: "en-maududi",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "Mubarakpuri",
    translator: "Safi-ur-Rahman al-Mubarakpuri",
    languageCode: "en",
    translationCode: "en-mubarakpuri",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "Pickthall",
    translator: "Mohammed Marmaduke William Pickthall",
    languageCode: "en",
    translationCode: "en-pickthall",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "Qarai",
    translator: "Ali Quli Qarai",
    languageCode: "en",
    translationCode: "en-qarai",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "Qaribullah & Darwish",
    translator: "Hasan al-Fatih Qaribullah and Ahmad Darwish",
    languageCode: "en",
    translationCode: "en-qaribullah",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "Saheeh International",
    translator: "Saheeh International",
    languageCode: "en",
    translationCode: "en-sahih",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "Sarwar",
    translator: "Muhammad Sarwar",
    languageCode: "en",
    translationCode: "en-sarwar",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "Shakir",
    translator: "Mohammad Habib Shakir",
    languageCode: "en",
    translationCode: "en-shakir",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "Transliteration",
    translator: "English Transliteration",
    languageCode: "en",
    translationCode: "en-transliteration",
    direction: "ltr",
    isSearchable: false
  },
  {
    language: "English",
    translationName: "Transliteration 2",
    translator: "English Transliteration 2",
    languageCode: "en",
    translationCode: "en-transliteration2",
    direction: "ltr",
    isSearchable: false
  },
  {
    language: "English",
    translationName: "Wahiduddin Khan",
    translator: "Wahiduddin Khan",
    languageCode: "en",
    translationCode: "en-wahiduddin",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "English",
    translationName: "Yusuf Ali",
    translator: "Abdullah Yusuf Ali",
    languageCode: "en",
    translationCode: "en-yusufali",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Finnish",
    translationName: "Finnish",
    translator: "Finnish",
    languageCode: "fi",
    translationCode: "fi-finnish",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "French",
    translationName: "Hamidullah",
    translator: "Muhammad Hamidullah",
    languageCode: "fr",
    translationCode: "fr-hamidullah",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "German",
    translationName: "Abu Rida",
    translator: "Abu Rida Muhammad ibn Ahmad ibn Rassoul",
    languageCode: "de",
    translationCode: "de-aburida",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "German",
    translationName: "Bubenheim & Elyas",
    translator: "A. S. F. Bubenheim and N. Elyas",
    languageCode: "de",
    translationCode: "de-bubenheim",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "German",
    translationName: "Khoury",
    translator: "Adel Theodor Khoury",
    languageCode: "de",
    translationCode: "de-khoury",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "German",
    translationName: "Zaidan",
    translator: "Amir Zaidan",
    languageCode: "de",
    translationCode: "de-zaidan",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Hausa",
    translationName: "Gumi",
    translator: "Abubakar Mahmoud Gumi",
    languageCode: "ha",
    translationCode: "ha-gumi",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Hindi",
    translationName: "फ़ारूक़ ख़ान & अहमद",
    translator: "Muhammad Farooq Khan and Muhammad Ahmed  ",
    languageCode: "hi",
    translationCode: "hi-farooq",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Hindi",
    translationName: "फ़ारूक़ ख़ान & नदवी",
    translator: "Suhel Farooq Khan and Saifur Rahman Nadwi",
    languageCode: "hi",
    translationCode: "hi-hindi",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Indonesian",
    translationName: "Bahasa Indonesia",
    translator: "Indonesian Ministry of Religious Affairs",
    languageCode: "id",
    translationCode: "id-indonesian",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Indonesian",
    translationName: "Quraish Shihab",
    translator: "Muhammad Quraish Shihab et al.",
    languageCode: "id",
    translationCode: "id-muntakhab",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Indonesian",
    translationName: "Tafsir Jalalayn",
    translator: "Jalal ad-Din al-Mahalli and Jalal ad-Din as-Suyuti",
    languageCode: "id",
    translationCode: "id-jalalayn",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Italian",
    translationName: "Piccardo",
    translator: "Hamza Roberto Piccardo",
    languageCode: "it",
    translationCode: "it-piccardo",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Japanese",
    translationName: "Japanese",
    translator: "Unknown",
    languageCode: "ja",
    translationCode: "ja-japanese",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Korean",
    translationName: "Korean",
    translator: "Unknown",
    languageCode: "ko",
    translationCode: "ko-korean",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Kurdish",
    translationName: "ته‌فسیری ئاسان",
    translator: "Burhan Muhammad-Amin",
    languageCode: "ku",
    translationCode: "ku-asan",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Malay",
    translationName: "Basmeih",
    translator: "Abdullah Muhammad Basmeih",
    languageCode: "ms",
    translationCode: "ms-basmeih",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Malayalam",
    translationName: "അബ്ദുല്‍ ഹമീദ് & പറപ്പൂര്‍",
    translator: "Cheriyamundam Abdul Hameed and Kunhi Mohammed Parappoor",
    languageCode: "ml",
    translationCode: "ml-abdulhameed",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Malayalam",
    translationName: "കാരകുന്ന് & എളയാവൂര്",
    translator: "Muhammad Karakunnu and Vanidas Elayavoor",
    languageCode: "ml",
    translationCode: "ml-karakunnu",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Maranao",
    translationName: "Maranao",
    translator: "Maranao",
    languageCode: "mrw",
    translationCode: "mrw-maranao",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Norwegian",
    translationName: "Einar Berg",
    translator: "Einar Berg",
    languageCode: "no",
    translationCode: "no-berg",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Persian",
    translationName: "الهی قمشه‌ای",
    translator: "Mahdi Elahi Ghomshei",
    languageCode: "fa",
    translationCode: "fa-ghomshei",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Persian",
    translationName: "انصاریان",
    translator: "Hussain Ansarian",
    languageCode: "fa",
    translationCode: "fa-ansarian",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Persian",
    translationName: "آیتی",
    translator: "AbdolMohammad Ayati",
    languageCode: "fa",
    translationCode: "fa-ayati",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Persian",
    translationName: "بهرام پور",
    translator: "Abolfazl Bahrampour",
    languageCode: "fa",
    translationCode: "fa-bahrampour",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Persian",
    translationName: "خرمدل",
    translator: "Mostafa Khorramdel",
    languageCode: "fa",
    translationCode: "fa-khorramdel",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Persian",
    translationName: "خرمشاهی",
    translator: "Baha'oddin Khorramshahi",
    languageCode: "fa",
    translationCode: "fa-khorramshahi",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Persian",
    translationName: "صادقی تهرانی",
    translator: "Mohammad Sadeqi Tehrani",
    languageCode: "fa",
    translationCode: "fa-sadeqi",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Persian",
    translationName: "فولادوند",
    translator: "Mohammad Mahdi Fooladvand",
    languageCode: "fa",
    translationCode: "fa-fooladvand",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Persian",
    translationName: "مجتبوی",
    translator: "Sayyed Jalaloddin Mojtabavi",
    languageCode: "fa",
    translationCode: "fa-mojtabavi",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Persian",
    translationName: "معزی",
    translator: "Mohammad Kazem Moezzi",
    languageCode: "fa",
    translationCode: "fa-moezzi",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Persian",
    translationName: "مکارم شیرازی",
    translator: "Naser Makarem Shirazi",
    languageCode: "fa",
    translationCode: "fa-makarem",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Polish",
    translationName: "Bielawskiego",
    translator: "Józefa Bielawskiego",
    languageCode: "pl",
    translationCode: "pl-bielawskiego",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Portuguese",
    translationName: "El-Hayek",
    translator: "Samir El-Hayek ",
    languageCode: "pt",
    translationCode: "pt-elhayek",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Romanian",
    translationName: "Grigore",
    translator: "George Grigore",
    languageCode: "ro",
    translationCode: "ro-grigore",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Russian",
    translationName: "Абу Адель",
    translator: "Abu Adel",
    languageCode: "ru",
    translationCode: "ru-abuadel",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Russian",
    translationName: "Аль-Мунтахаб",
    translator: "Ministry of Awqaf, Egypt",
    languageCode: "ru",
    translationCode: "ru-muntahab",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Russian",
    translationName: "Крачковский",
    translator: "Ignaty Yulianovich Krachkovsky",
    languageCode: "ru",
    translationCode: "ru-krachkovsky",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Russian",
    translationName: "Кулиев",
    translator: "Elmir Kuliev",
    languageCode: "ru",
    translationCode: "ru-kuliev",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Russian",
    translationName: "Османов",
    translator: "Magomed-Nuri Osmanovich Osmanov",
    languageCode: "ru",
    translationCode: "ru-osmanov",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Russian",
    translationName: "Порохова",
    translator: "V. Porokhova",
    languageCode: "ru",
    translationCode: "ru-porokhova",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Russian",
    translationName: "Саблуков",
    translator: "Gordy Semyonovich Sablukov",
    languageCode: "ru",
    translationCode: "ru-sablukov",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Sindhi",
    translationName: "امروٽي",
    translator: "Taj Mehmood Amroti",
    languageCode: "sd",
    translationCode: "sd-amroti",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Somali",
    translationName: "Abduh",
    translator: "Mahmud Muhammad Abduh",
    languageCode: "so",
    translationCode: "so-abduh",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Spanish",
    translationName: "Bornez",
    translator: "Raúl González Bórnez",
    languageCode: "es",
    translationCode: "es-bornez",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Spanish",
    translationName: "Cortes",
    translator: "Julio Cortes ",
    languageCode: "es",
    translationCode: "es-cortes",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Spanish",
    translationName: "Garcia",
    translator: "Muhammad Isa García",
    languageCode: "es",
    translationCode: "es-garcia",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Swahili",
    translationName: "Al-Barwani",
    translator: "Ali Muhsin Al-Barwani",
    languageCode: "sw",
    translationCode: "sw-barwani",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Swedish",
    translationName: "Bernström",
    translator: "Knut Bernström",
    languageCode: "sv",
    translationCode: "sv-bernstrom",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Tajik",
    translationName: "Оятӣ",
    translator: "AbdolMohammad Ayati",
    languageCode: "tg",
    translationCode: "tg-ayati",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Tamil",
    translationName: "ஜான் டிரஸ்ட்",
    translator: "Jan Turst Foundation",
    languageCode: "ta",
    translationCode: "ta-tamil",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Tatar",
    translationName: "Yakub Ibn Nugman",
    translator: "Yakub Ibn Nugman",
    languageCode: "tt",
    translationCode: "tt-nugman",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Thai",
    translationName: "ภาษาไทย",
    translator: "King Fahad Quran Complex",
    languageCode: "th",
    translationCode: "th-thai",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Turkish",
    translationName: "Abdulbakî Gölpınarlı",
    translator: "Abdulbaki Golpinarli",
    languageCode: "tr",
    translationCode: "tr-golpinarli",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Turkish",
    translationName: "Alİ Bulaç",
    translator: "Alİ Bulaç",
    languageCode: "tr",
    translationCode: "tr-bulac",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Turkish",
    translationName: "Çeviriyazı",
    translator: "Muhammet Abay",
    languageCode: "tr",
    translationCode: "tr-transliteration",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Turkish",
    translationName: "Diyanet İşleri",
    translator: "Diyanet Isleri",
    languageCode: "tr",
    translationCode: "tr-diyanet",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Turkish",
    translationName: "Diyanet Vakfı",
    translator: "Diyanet Vakfi",
    languageCode: "tr",
    translationCode: "tr-vakfi",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Turkish",
    translationName: "Edip Yüksel",
    translator: "Edip Yüksel",
    languageCode: "tr",
    translationCode: "tr-yuksel",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Turkish",
    translationName: "Elmalılı Hamdi Yazır",
    translator: "Elmalili Hamdi Yazir",
    languageCode: "tr",
    translationCode: "tr-yazir",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Turkish",
    translationName: "Öztürk",
    translator: "Yasar Nuri Ozturk",
    languageCode: "tr",
    translationCode: "tr-ozturk",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Turkish",
    translationName: "Suat Yıldırım",
    translator: "Suat Yildirim",
    languageCode: "tr",
    translationCode: "tr-yildirim",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Turkish",
    translationName: "Süleyman Ateş",
    translator: "Suleyman Ates",
    languageCode: "tr",
    translationCode: "tr-ates",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Ukrainian",
    translationName: "Yakubovych",
    translator: "Mykhaylo Yakubovych",
    languageCode: "uk",
    translationCode: "uk-yakubovych",
    direction: "ltr",
    isSearchable: true
  },
  {
    language: "Urdu",
    translationName: "ابوالاعلی مودودی",
    translator: "Abul A'ala Maududi",
    languageCode: "ur",
    translationCode: "ur-maududi",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Urdu",
    translationName: "احمد رضا خان",
    translator: "Ahmed Raza Khan",
    languageCode: "ur",
    translationCode: "ur-kanzuliman",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Urdu",
    translationName: "احمد علی",
    translator: "Ahmed Ali",
    languageCode: "ur",
    translationCode: "ur-ahmedali",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Urdu",
    translationName: "جالندہری",
    translator: "Fateh Muhammad Jalandhry",
    languageCode: "ur",
    translationCode: "ur-jalandhry",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Urdu",
    translationName: "طاہر القادری",
    translator: "Tahir ul Qadri",
    languageCode: "ur",
    translationCode: "ur-qadri",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Urdu",
    translationName: "علامہ جوادی",
    translator: "Syed Zeeshan Haider Jawadi",
    languageCode: "ur",
    translationCode: "ur-jawadi",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Urdu",
    translationName: "محمد جوناگڑھی",
    translator: "Muhammad Junagarhi",
    languageCode: "ur",
    translationCode: "ur-junagarhi",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Urdu",
    translationName: "محمد حسین نجفی",
    translator: "Ayatollah Muhammad Hussain Najafi",
    languageCode: "ur",
    translationCode: "ur-najafi",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Uyghur",
    translationName: "محمد صالح",
    translator: "Muhammad Saleh",
    languageCode: "ug",
    translationCode: "ug-saleh",
    direction: "rtl",
    isSearchable: true
  },
  {
    language: "Uzbek",
    translationName: "Мухаммад Содик",
    translator: "Muhammad Sodik Muhammad Yusuf",
    languageCode: "uz",
    translationCode: "uz-sodik",
    direction: "ltr",
    isSearchable: true
  }
];
