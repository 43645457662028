import React from "react";
import { Helmet } from "react-helmet";
import { Chapter } from "../models/Chapter";
import { websiteUrl } from "../constants/BaseConstants";

interface MetaTagsProps {
  chapter: Chapter;
}

function MetaTags() {
  const iconUrl = `${websiteUrl}images/quran-multilingual-icon.png`;
  const infographicUrl = `${websiteUrl}images/quran-multilingual-infographic.jpg`;

  const title = `Quran with Translations and Recitations, in a Modern Interface.`;
  const description = `Quran in English, Arabic, Urdu, and other languages, in a simple interface. Read Quran and listen online. Mobile friendly. Clear audio. Scalable text. Night theme. Verse repeat. Parallel text. Intuitive interface. Search Quran and translations. This is a great educational tool. Quran Arabic, English, Urdu, Turkish, Persian, Hindi, Bengali, Spanish, French, Chinese, German, Indonesian, Malay, Chinese, ... Al-Afasy, Abdul Bassit, Sudais, Shuraim, Al-Houssary, Al-Ghamdi, Alili, Minshawi, Al-Huthaify, ...`;

  return (
    <Helmet
      // structured data
      script={[
        {
          type: "application/ld+json",
          innerHTML: `{
            "@context": "https://schema.org",
            "@type": "Website",
            "url": "${websiteUrl}",
            "name": "QuranWow",
            "description": "${description}",      
            "author": "Talal Itani",
            "image": "[${iconUrl}, ${infographicUrl}]"
          }`
        }
      ]}
    >
      <html lang="en" />

      {/* meta tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="author" content="Talal Itani" />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={websiteUrl} />

      <meta property="og:url" content={websiteUrl} />
      <meta property="og:type" content="book" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={infographicUrl} />
      <meta property="og:image:secure_url" content={iconUrl} />
      <meta property="og:site_name" content="Quran with Translations and Recitations" />
      <meta property="fb:admins" content="683971979" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@ClearQuran" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:creator" content="@ClearQuran" />
      <meta name="twitter:image" content={infographicUrl} />
    </Helmet>
  );
}

export default MetaTags;
