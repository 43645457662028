import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { Chapter } from "../models/Chapter";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles({
  dialog: {
    maxWidth: "625px",
    margin: "auto"
  },
  verseNumber: {
    minWidth: "40px",
    margin: "00px 15px 15px 00px",
    padding: "0px"
  },
  verseNumberSelected: {
    backgroundColor: "#cccccc"
  },
  closeButton: {
    position: "absolute",
    right: "12px",
    top: "10px",
    color: "#909090"
  }
});

interface VersesDialogProps {
  isOpen: boolean;
  chapter: Chapter;
  verseNumber: number;
  verseNumberClicked: Function;
  handleCloseDialog: (event: React.MouseEvent<HTMLElement>) => void;
}

const arePropsEqual = (prevProps: VersesDialogProps, nextProps: VersesDialogProps) => {
  const areEqual =
    prevProps.isOpen === nextProps.isOpen && prevProps.chapter === nextProps.chapter && prevProps.verseNumber === nextProps.verseNumber;
  return areEqual;
};

function VersesDialog(props: VersesDialogProps) {
  const classes = useStyles(props);

  return (
    <Dialog
      keepMounted={true}
      className={classes.dialog}
      open={props.isOpen}
      onClose={props.handleCloseDialog}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">
        Scroll to Ayah &bull; Verse
        <IconButton aria-label="close" className={classes.closeButton} onClick={props.handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {props.chapter.verseNumbers.map((verseNumber: number) => (
          <Button
            size="large"
            key={"verse" + verseNumber}
            className={classes.verseNumber + " " + (verseNumber === props.verseNumber ? classes.verseNumberSelected : null)}
            onClick={() => props.verseNumberClicked(verseNumber)}
          >
            {verseNumber}
          </Button>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCloseDialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(VersesDialog, arePropsEqual);
