import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import { JUZTABLE } from "../constants/JuzTable";

const useStyles = makeStyles({
  title: {
    width: "120px",
    textAlign: "center",
    paddingLeft: "7px"
  },
  chapterNumber: {
    fontSize: "16px",
    textAlign: "center",
    width: "100px"
  }
});

interface JuzMenuProps {
  anchor: HTMLElement;
  juzClicked: Function;
  handleMenuClose: Function;
  chapterNumber: number;
  verseNumber: number;
}

const arePropsEqual = (prevProps: JuzMenuProps, nextProps: JuzMenuProps) => {
  const areEqual =
    prevProps.anchor === nextProps.anchor &&
    prevProps.chapterNumber === nextProps.chapterNumber &&
    prevProps.verseNumber === nextProps.verseNumber;
  return areEqual;
};

function JuzMenu(props: JuzMenuProps) {
  const classes = useStyles(props);
  const [juzIndex, setJuzIndex] = React.useState(0);

  React.useEffect(() => {
    for (let i = 29; i >= 0; i--) {
      if (props.chapterNumber >= JUZTABLE[i][0] && props.verseNumber >= JUZTABLE[i][1]) {
        setJuzIndex(i);
        break;
      }
    }
  }, [props.chapterNumber, props.verseNumber]);

  return (
    <Menu anchorEl={props.anchor} open={Boolean(props.anchor)} onClose={() => props.handleMenuClose(null)}>
      <Box m={1} />
      <Typography className={classes.title}>Juz - Para</Typography>
      <Box m={1} />
      {JUZTABLE.map((item: number[], index: number) => (
        <MenuItem key={"juz-menu" + index} selected={juzIndex === index} onClick={() => props.juzClicked(index)}>
          <span className={classes.chapterNumber}>{index + 1} </span>
        </MenuItem>
      ))}
      <Box m={1} />
    </Menu>
  );
}

export default React.memo(JuzMenu, arePropsEqual);
