import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fade } from "@material-ui/core/styles/colorManipulator";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import { SearchResult } from "../models/SearchResult";
import queryString from "query-string"; // convert object into url query string
import { ChapterVerseLocation } from "../models/ChapterVerseLocation";
import { VerseLocationForText } from "../models/VerseLocationForText";
import "../css/global-styles.css";
import { isIOS } from "react-device-detect";
import { ChapterInfo } from "../models/ChapterInfo";
import FormHelperText from "@material-ui/core/FormHelperText";
import { azureSearchRootUrl } from "../constants/BaseConstants";
import { TransitionProps } from "@material-ui/core/transitions";
import { TEXTBOOKS } from "../constants/Textbooks";
import { quranFonts } from "../constants/QuranFonts";
import { ToHindi } from "../common/ToHindi";
import { SearchableLanguages } from "../constants/SearchableLanguages";
import Snackbar from "@material-ui/core/Snackbar";
import Checkbox from "@material-ui/core/Checkbox";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";
import { iconColor } from "../constants/BaseConstants";
import { blueUrlColor } from "../constants/BaseConstants";
import HelpOutline from "@material-ui/icons/HelpOutline";
import InfoDialog from "../components/InfoDialog";
import { arraysEqual } from "../common/ArraysEqual";
// @ts-ignore
import renderHTML from "react-render-html";
import { gaEvent } from "../common/GaEvent";

const defaultFontSizeB = 1.08; // in rem
const resultsPerRequest = 20;

let searchResultsLocal: SearchResult[];

const queryObject = {
  "api-version": "2019-05-06",
  "api-key": "50140970F6927FB81BFA79662E7F86FA",
  queryType: "full",
  searchMode: "all",
  $count: "true",
  $top: resultsPerRequest,
  highlightPreTag: "<b>",
  highlightPostTag: "</b>",
  $orderby: "verseIndex, weighting desc",
  searchFields: "", // will be added later
  highlight: "", // will be added later
  search: "", // will be added later
  $filter: "", // will be added later
  $skip: 0 // will be aded later
};

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(2)
  },
  searchBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  form: {
    flexGrow: 1,
    width: "90%"
  },
  inputBase: {
    fontSize: "16px",
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', // doing this because using a native element (MUI input text element does not support dir)
    paddingLeft: "15px",
    paddingRight: "15px",
    height: "37px",
    color: "#303030",
    backgroundColor: "#f0f0f0",
    width: "100%",
    border: "1px solid #aaaaaa",
    borderRight: "none",
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    WebkitAppearance: "none"
  },
  searchButton: {
    height: "37px",
    minWidth: "40px",
    maxWidth: "40px",
    border: "1px solid #aaaaaa",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    backgroundColor: "#e0e0e0",
    color: "#606060",
    padding: 0
  },
  inputRoot: {
    color: "inherit"
  },
  appBar: {
    position: "relative"
  },
  dialogContent: {
    padding: "0",
    margin: "0",
    overflowY: "scroll"
  },
  content: {
    maxWidth: "750px",
    paddingTop: "40px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: "12px",
    paddingRight: "12px"
  },
  verse: {
    cursor: "pointer",
    lineHeight: "1.5",
    marginBottom: "0.9rem",
    color: "#333",
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', // doing this because MUI Typography added another div
    boxShadow: "0 1px 4px 0 rgba(0,0,0,0.2)",
    borderRadius: "5px",
    padding: "10px 10px 10px 10px"
  },
  wasNotClicked: {
    color: blueUrlColor
  },
  wasClicked: {
    color: "#609"
  },
  spinnerMoreButtonCombo: {
    margin: "30px 0px 30px 0px",
    height: "50px"
  },
  spinner: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  loadMoreButton: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  numberOfResultsFound: {
    fontSize: "17px",
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "5px"
  },
  selector: {
    width: "100%",
    margin: "30px 0px 25px 0px"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: "2px"
  },
  language: {
    color: "#C30035",
    fontWeight: "bold",
    fontSize: "18px",
    marginTop: "10px",
    marginLeft: "10px"
  },
  appBarTitle: {
    color: iconColor,
    marginLeft: theme.spacing(2),
    flex: 1
  },
  appBarIcon: {
    color: iconColor
  },
  grayedOut: {
    color: "#aaaaaa"
  }
}));

const ColorButton = withStyles(() => ({
  root: {
    color: "#ffffff",
    backgroundColor: blueUrlColor,
    "&:hover": {
      backgroundColor: fade(blueUrlColor, 0.9)
    }
  }
}))(Button);

const ColorProgress = withStyles({
  root: {
    color: blueUrlColor
  }
})(CircularProgress);

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getSearchResultsCount(searchReply: any) {
  return searchReply["@odata.count"];
}

interface SearchProps {
  fontScaling: number;
  isOpen: boolean;
  searchVerseClick: Function;
  handleClose: () => void;
  chaptersInfo: ChapterInfo[];
  selectedSearchTextBooks: string[];
  defaultTextBooks: string[];
  handleSearchTextBooksChange: Function;
  quranFont: string;
  withStar: string[];
}

const arePropsEqual = (prevProps: SearchProps, nextProps: SearchProps) => {
  const areEqual =
    prevProps.fontScaling === nextProps.fontScaling &&
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.isOpen === nextProps.isOpen &&
    arraysEqual(prevProps.selectedSearchTextBooks, nextProps.selectedSearchTextBooks) &&
    prevProps.quranFont === nextProps.quranFont;
  return areEqual;
};

function SearchDialog(props: SearchProps) {
  const classes = useStyles(props);
  const [searchResults, setSearchResults] = React.useState([]);
  const [isSpinnerVisible, setIsSpinnerVisible] = React.useState(false);
  const [isLoadMoreButtonVisible, setIsLoadMoreButtonVisible] = React.useState(false);
  const [numberOfResultsFound, setNumberOfResultsFound] = React.useState(-1);
  const [isHelpDialogOpen, setIsHelpDialogOpen] = React.useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);

  function handleSnackBarClose() {
    setIsSnackBarOpen(false);
  }

  // TextBooks
  let buffer = [];
  for (let i = 0; i < TEXTBOOKS.length; i++) {
    if (i === 0 || (i > 0 && TEXTBOOKS[i].language !== TEXTBOOKS[i - 1].language)) {
      let grayedOutClass = SearchableLanguages.includes(TEXTBOOKS[i].languageCode) ? null : classes.grayedOut;
      buffer.push(
        <Typography className={classes.language + " " + grayedOutClass} key={"language-item" + i}>
          {" "}
          {TEXTBOOKS[i].language}
        </Typography>
      );
    }
    if (SearchableLanguages.includes(TEXTBOOKS[i].languageCode) && TEXTBOOKS[i].isSearchable) {
      const star = props.withStar.includes(TEXTBOOKS[i].translationCode) ? " ★" : "";
      buffer.push(
        <MenuItem value={TEXTBOOKS[i].translationCode} key={"textbook-item" + i}>
          <Checkbox checked={props.selectedSearchTextBooks.includes(TEXTBOOKS[i].translationCode)} />
          <ListItemText primary={TEXTBOOKS[i].translationName + star} />
        </MenuItem>
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function textBooksChanged(event: any) {
    // when the user clicks a language, this method is triggered, and there is an undefined value. So send array only if all values are defined
    let allGood = true;
    for (let i = 0; i < event.target.value.length; i++) {
      if (typeof event.target.value[i] == "undefined") {
        allGood = false;
        break;
      }
    }
    if (allGood) {
      // allow search in only one language
      var newArray: string[] = [];
      for (let i = 0; i < event.target.value.length; i++) {
        if (event.target.value[i].split("-")[0] === event.target.value[event.target.value.length - 1].split("-")[0]) {
          newArray.push(event.target.value[i]);
        }
      }
      props.handleSearchTextBooksChange(newArray);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function textBooksClosed() {
    if (props.selectedSearchTextBooks.length < 1) {
      props.handleSearchTextBooksChange(props.defaultTextBooks);
      setIsSnackBarOpen(true);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getSearchResultsObjects(searchReply: any) {
    var searchResults: SearchResult[] = [];
    for (var i = 0; i < searchReply.value.length; i++) {
      var textFieldName = "text_" + searchReply.value[i].languageCode;
      // special case for chinese - because language name was incorrectly programmed into the azure search index
      if (searchReply.value[i].translationCode === "zh-jian") {
        textFieldName = "text_zh_Hans";
      } else if (searchReply.value[i].translationCode === "zh-majian") {
        textFieldName = "text_zh_Hant";
      }
      // loop through all snippets, and get highlighted words from the snippets
      var allHighlightedWords: string[] = [];
      for (let snippet of searchReply.value[i]["@search.highlights"][textFieldName]) {
        var highlightedWords = snippet.match(/<b>(.*?)<\/b>/g).map(function(val: string) {
          return val.replace(/<\/?b>/g, "");
        });
        allHighlightedWords = allHighlightedWords.concat(highlightedWords); // add to array
      }
      allHighlightedWords = allHighlightedWords.filter((item: string, index: number) => allHighlightedWords.indexOf(item) === index); // remove duplicates
      let returnedText = searchReply.value[i][textFieldName];
      // now highlight what needs highlighting
      for (let highlightedWord of allHighlightedWords) {
        returnedText = returnedText.replace(
          new RegExp(highlightedWord, "g"),
          '<span style="background-color:#ffff00">' + highlightedWord + "</span>"
        );
      }
      searchResults.push(
        new SearchResult(
          searchReply.value[i].chapterNumber,
          searchReply.value[i].verseNumber,
          returnedText,
          false,
          searchReply.value[i].direction,
          searchReply.value[i].languageCode,
          searchReply.value[i].translationCode
        )
      );
    }
    return searchResults;
  }

  function searchAzure(searchString: string) {
    setIsLoadMoreButtonVisible(false);
    setIsSpinnerVisible(true);
    queryObject.search = searchString
      .replace(/!/g, "") // remove exclamation mark globally
      .replace(/[?=]/g, "") // remove question mark globally
      .replace(/\//g, ""); // remove forward slash globally

    // form $filter
    queryObject.$filter = "";
    for (let i = 0; i < props.selectedSearchTextBooks.length; i++) {
      queryObject.$filter = `${queryObject.$filter}translationCode eq '${
        TEXTBOOKS.filter(book => book.translationCode === props.selectedSearchTextBooks[i])[0].translationCode
      }'`;
      if (i < props.selectedSearchTextBooks.length - 1) {
        queryObject.$filter = queryObject.$filter + " or ";
      }
    }

    // Get list of all selected language codes
    var languageCodes: string[] = [];
    for (let i = 0; i < props.selectedSearchTextBooks.length; i++) {
      //languageCodes.push(props.selectedSearchTextBooks[i].split("-")[0]);
      languageCodes.push(TEXTBOOKS.filter(book => book.translationCode === props.selectedSearchTextBooks[i])[0].languageCode);
    }
    languageCodes = [...new Set(languageCodes)];

    // form searchFields and highlight
    queryObject.searchFields = "";
    queryObject.highlight = "";
    for (let i = 0; i < languageCodes.length; i++) {
      queryObject.searchFields = `${queryObject.searchFields}text_${languageCodes[i]}`;
      queryObject.highlight = `${queryObject.highlight}text_${languageCodes[i]}`;
      if (i < languageCodes.length - 1) {
        queryObject.searchFields = queryObject.searchFields + ", ";
        queryObject.highlight = queryObject.highlight + ", ";
      }
    }

    queryObject.$skip = searchResultsLocal.length;

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const queryStr = queryString.stringify(queryObject);
    axios
      .get(azureSearchRootUrl + "?" + queryStr, {
        headers: {
          dataType: "json"
        }
      })
      .then(res => {
        const searchReply = res.data;
        if (searchReply.value.length > 0) {
          searchResultsLocal = searchResultsLocal.concat(getSearchResultsObjects(searchReply));
          setSearchResults(searchResultsLocal);
        }
        setNumberOfResultsFound(searchReply["@odata.count"]);
        setIsSpinnerVisible(false);
        if (searchResultsLocal.length < getSearchResultsCount(searchReply)) {
          setIsLoadMoreButtonVisible(true);
        }
      })
      .catch(function() {
        setIsSpinnerVisible(false);
        setIsLoadMoreButtonVisible(false);
      });
  }

  function onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    setNumberOfResultsFound(-1);
    setSearchResults([]);
    searchResultsLocal = [];
    if (event != null) {
      event.preventDefault();
      // @ts-ignore
      searchAzure(document.getElementById("input-base-search").value.toString());
      gaEvent("Search Azure");
    }
    document.getElementById("input-base-search").blur(); // to close the virtual keyboard
  }

  function onLoadMore(event: React.MouseEvent<HTMLButtonElement>) {
    if (event != null) {
      event.preventDefault();
      // @ts-ignore
      searchAzure(document.getElementById("input-base-search").value.toString());
      gaEvent("Search Azure Load More");
    }
  }

  function verseClicked(event: React.MouseEvent<HTMLElement>) {
    // make sure click not triggered by user selecting text
    if (window.getSelection().toString().length === 0) {
      const stringArray = event.currentTarget.id.replace("search-result-", "").split("-");
      const gotoLocation = new ChapterVerseLocation(
        Number(stringArray[0]),
        new VerseLocationForText(Number(stringArray[1]), true, true, 0)
      );
      const index = Number(stringArray[2]);
      let objs = searchResults;
      objs[index].wasClicked = true; // to change color when a verse is clicked
      setSearchResults(objs);
      props.searchVerseClick(gotoLocation);
      gaEvent("Verse Clicked in Search");
    }
  }

  function getChapterNameArabic(chapterNumber: number) {
    return props.chaptersInfo[chapterNumber - 1].nameArabic;
  }

  React.useEffect(() => {
    if (props.isOpen === true && searchResults.length === 0) {
      var inputElement = document.getElementById("input-base-search"); // put cursor in search box under some conditions
      if (!isIOS) {
        // focus caused problems with iOS
        inputElement.focus();
      }
      inputElement.addEventListener("mouseover", function() {
        // mouseover event listener - to put cursor in search box when mouseover
        inputElement.focus();
      });
    }
  }, [props.isOpen]);

  let arabicBlockStyle = {
    fontSize: ((props.fontScaling * quranFonts.filter(font => font.name === props.quranFont)[0].scaling) / 100).toString() + "rem"
  };

  let englishBlockStyle = {
    fontSize: ((props.fontScaling * defaultFontSizeB) / 100).toString() + "rem"
  };

  return (
    <Dialog keepMounted fullScreen open={props.isOpen} onClose={props.handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" onClick={props.handleClose} aria-label="close" className={classes.appBarIcon}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.appBarTitle}>
            Search
          </Typography>
          <IconButton edge="start" onClick={() => setIsHelpDialogOpen(true)} aria-label="close" className={classes.appBarIcon}>
            <HelpOutline />
          </IconButton>
          <Button autoFocus onClick={props.handleClose} className={classes.appBarIcon}>
            Close
          </Button>
        </Toolbar>
      </AppBar>

      <DialogContent className={classes.dialogContent}>
        <div className={classes.content}>
          <div className={classes.searchBlock}>
            <form noValidate autoComplete="off" onSubmit={onFormSubmit} action="." id="search-form" className={classes.form}>
              <input placeholder="Search Quran…" id="input-base-search" className={classes.inputBase} type="search" dir="auto" required />
            </form>
            <Button variant="contained" className={classes.searchButton} type="submit" form="search-form" disableElevation>
              <SearchIcon />
            </Button>
          </div>

          {/* Search Text Books Selector */}
          <FormControl className={classes.selector}>
            <InputLabel>Search In</InputLabel>
            <Select
              autoWidth={true}
              multiple
              value={props.selectedSearchTextBooks}
              onChange={textBooksChanged}
              onClose={textBooksClosed}
              input={<Input />}
              renderValue={() => (
                <div className={classes.chips}>
                  {props.selectedSearchTextBooks.map((translationCode: string) => (
                    <Chip
                      key={translationCode}
                      label={TEXTBOOKS.filter(book => book.translationCode === translationCode)[0].translationName}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
            >
              {buffer}
            </Select>
            <FormHelperText>Select One or More - Same Language</FormHelperText>
          </FormControl>

          <Typography
            className={classes.numberOfResultsFound}
            style={numberOfResultsFound === 0 ? { display: "block" } : { display: "none" }}
          >
            No results found
          </Typography>
          <Typography
            className={classes.numberOfResultsFound}
            style={numberOfResultsFound === 1 ? { display: "block" } : { display: "none" }}
          >
            1 result found
          </Typography>
          <Typography
            className={classes.numberOfResultsFound}
            style={numberOfResultsFound > 1 ? { display: "block" } : { display: "none" }}
          >
            {numberOfResultsFound} results found
          </Typography>
          {searchResults.map((item, index) => (
            <div
              className={classes.verse}
              key={`search-result-${item.chapterNumber.toString()}-${item.verseNumber.toString()}`}
              id={`search-result-${item.chapterNumber.toString()}-${item.verseNumber.toString()}-${index.toString()}`}
              onClick={verseClicked}
              dir={item.direction === "rtl" ? "rtl" : "ltf"} // There is a bug in the azure search index - rtl is misspelled into rtf
              lang={item.languageCode}
            >
              {item.translationCode !== "ar-allah" && (
                <div style={englishBlockStyle}>
                  <span className={item.wasClicked ? classes.wasClicked : classes.wasNotClicked}>
                    [
                    {item.direction === "rtl"
                      ? ToHindi(item.verseNumber, item.languageCode)
                      : ToHindi(item.chapterNumber, item.languageCode)}
                    :
                    {item.direction === "rtl"
                      ? ToHindi(item.chapterNumber, item.languageCode)
                      : ToHindi(item.verseNumber, item.languageCode)}
                    &nbsp;{TEXTBOOKS.filter(textB => textB.translationCode === item.translationCode)[0].translationName}]
                  </span>
                  &thinsp;&thinsp;
                  <span contentEditable={false}>{renderHTML(item.text)}</span>
                </div>
              )}
              {item.translationCode === "ar-allah" && (
                <div style={arabicBlockStyle}>
                  <span
                    className={item.wasClicked ? classes.wasClicked : classes.wasNotClicked}
                    style={{
                      fontFamily: quranFonts.filter(font => font.name === props.quranFont)[0].cssNameForNumber
                    }}
                  >
                    ﴿{getChapterNameArabic(item.chapterNumber)} {ToHindi(item.verseNumber, "ar")}﴾
                  </span>
                  &thinsp;&thinsp;
                  <span
                    contentEditable={false}
                    style={{
                      fontFamily: quranFonts.filter(font => font.name === props.quranFont)[0].cssName,
                      lineHeight: quranFonts.filter(font => font.name === props.quranFont)[0].lineHeight,
                      wordSpacing: quranFonts.filter(font => font.name === props.quranFont)[0].wordSpacing
                    }}
                  >
                    {renderHTML(item.text)}
                  </span>
                </div>
              )}
            </div>
          ))}

          <div className={classes.spinnerMoreButtonCombo}>
            <ColorProgress size={30} className={classes.spinner} style={isSpinnerVisible ? { display: "block" } : { display: "none" }} />
            <ColorButton
              variant="contained"
              className={classes.loadMoreButton}
              style={isLoadMoreButtonVisible ? { display: "block" } : { display: "none" }}
              onClick={onLoadMore}
            >
              More
            </ColorButton>
          </div>
        </div>
      </DialogContent>
      <InfoDialog
        paragraphs={[
          "• Search in one or more texts - same language",
          "• Use one or more words. Example: allah jesus spirit",
          "• Use the OR keyword. Example: allah OR jesus",
          "• Use a hyphen to exclude words. Example: allah -jesus",
          "• Some languages are not searchable. Unsearchable languages are disabled.",
          "• Use the asterisk wildcard. Example: honor* returns honor, honorable, honorably, etc.",
          "• Do fuzzy search by appending the tilde ~ symbol at the end of a word. Example: inform~ returns inform, reform, conform, etc."
        ]}
        title1="Search Quran"
        title2=""
        isOpen={isHelpDialogOpen}
        handleCloseDialog={() => setIsHelpDialogOpen(false)}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={isSnackBarOpen}
        autoHideDuration={10000}
        onClose={handleSnackBarClose}
        message="Search textbooks restored to default."
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleSnackBarClose}></Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </Dialog>
  );
}

export default React.memo(SearchDialog, arePropsEqual);
