import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import "../css/global-styles.css";
import { topAppBarHeight } from "../constants/BaseConstants";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/KeyboardBackspace";
import HelpOutline from "@material-ui/icons/HelpOutline";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { quranFonts } from "../constants/QuranFonts";
import { QuranFont } from "../models/QuranFont";
import { fontScalings } from "../constants/FontScalings";
import { verseRepeats } from "../constants/VerseRepeats";
import { TEXTBOOKS } from "../constants/Textbooks";
import { RECITATIONS } from "../constants/Recitations";
import ListSubheader from "@material-ui/core/ListSubheader";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import { isIOS } from "react-device-detect";

const useStyles = makeStyles({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.0)"
  },
  drawer: {
    display: "flex",
    flexDirection: "column",
    margin: "20px 15px 0px 15px"
  },
  drawerHeader: {
    display: "inline-block",
    alignItems: "center",
    justifyContent: "center",
    height: topAppBarHeight
  },
  drawerContent: {
    margin: "20px 15px 0px 15px",
    width: "200px",
    ["@media (min-width:500px)"]: {
      width: "220px"
    },
    ["@media (min-width:600px)"]: {
      width: "240px"
    }
  },
  selector: {
    width: "100%",
    margin: "0px 0px 25px 0px"
  },
  switch: {
    margin: "0px 0px 6px 0px"
  },
  chips: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap"
  },
  chip: {
    margin: "2px",
    width: "min-content"
  },
  language: {
    color: "#C30035",
    fontWeight: "bold",
    fontSize: "18px",
    marginTop: "10px",
    marginLeft: "10px"
  },
  narrowMenuItem: {
    textAlign: "center",
    width: "80px"
  },
  snackbar: {
    bottom: "60px"
  },
  topButton: {
    marginLeft: "10px"
  }
});

interface SettingsDrawerProps {
  selectedTextBooks: string[];
  defaultTextBooks: string[];
  handleTextBooksChange: Function;

  selectedRecitations: string[];
  defaultRecitations: string[];
  handleRecitationsChange: Function;

  withStar: string[];

  isOpen: boolean;
  handleCloseDrawer: () => void;
  handleOpenDrawer: () => void;

  handleHelpButtonClicked: () => void;

  selectedFontScaling: number;
  handleFontScalingChange: Function;

  selectedQuranFont: string;
  handleQuranFontChange: Function;

  isDarkTheme: boolean;
  handleDarkThemeChange: Function;

  selectedAutoScroll: boolean;
  handleAutoScrollChange: Function;

  autoAdvanceChapter: boolean;
  handleAutoAdvanceChapter: Function;

  verseRepeat: number;
  handleVerseRepeatChange: Function;

  isParallelText: boolean;
  handleParallelTextChange: Function;
}

function SettingsDrawer(props: SettingsDrawerProps) {
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const classes = useStyles(props);

  function handleSnackBarClose() {
    setIsSnackBarOpen(false);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function textBooksChanged(event: any) {
    // when the user clicks a language, this method is triggered, and there is an undefined value. So send array only if all values are defined
    let allGood = true;
    for (let i = 0; i < event.target.value.length; i++) {
      if (typeof event.target.value[i] == "undefined") {
        allGood = false;
        break;
      }
    }
    if (allGood) {
      props.handleTextBooksChange(event.target.value);
    }
  }

  function textBooksClosed() {
    if (props.selectedTextBooks.length < 1) {
      props.handleTextBooksChange(props.defaultTextBooks);
      setSnackBarMessage("Text restored to default");
      setIsSnackBarOpen(true);
    }
  }

  function recitationsClosed() {
    if (props.selectedRecitations.length < 1) {
      props.handleRecitationsChange(props.defaultRecitations);
      setSnackBarMessage("Recitations restored to default");
      setIsSnackBarOpen(true);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function recitationsChanged(event: any) {
    // when the user clicks a language, this method is triggered, and there is an undefined value. So send array only if all values are defined
    let allGood = true;
    for (let i = 0; i < event.target.value.length; i++) {
      if (typeof event.target.value[i] == "undefined") {
        allGood = false;
        break;
      }
    }
    if (allGood) {
      props.handleRecitationsChange(event.target.value);
    }
  }

  // TextBooks
  let buffer = [];
  for (let i = 0; i < TEXTBOOKS.length; i++) {
    if (i === 0 || (i > 0 && TEXTBOOKS[i].language !== TEXTBOOKS[i - 1].language)) {
      buffer.push(
        <Typography className={classes.language} key={"language-item" + i}>
          {" "}
          {TEXTBOOKS[i].language}
        </Typography>
      );
    }
    let star = props.withStar.includes(TEXTBOOKS[i].translationCode) ? " ★" : "";
    buffer.push(
      <MenuItem value={TEXTBOOKS[i].translationCode} key={"textbook-item" + i}>
        <Checkbox checked={props.selectedTextBooks.includes(TEXTBOOKS[i].translationCode)} />
        <ListItemText primary={TEXTBOOKS[i].translationName + star} />
      </MenuItem>
    );
  }

  // Recitations
  let bufferRecitations = [];
  for (let i = 0; i < RECITATIONS.length; i++) {
    if (i === 0 || (i > 0 && RECITATIONS[i].language !== RECITATIONS[i - 1].language)) {
      bufferRecitations.push(
        <Typography className={classes.language} key={"recitation-language-item" + i}>
          {" "}
          {RECITATIONS[i].language}
        </Typography>
      );
    }
    let star = props.withStar.includes(RECITATIONS[i].code) ? " ★" : "";
    bufferRecitations.push(
      <MenuItem value={RECITATIONS[i].code} key={"recitation-item" + i}>
        <Checkbox checked={props.selectedRecitations.includes(RECITATIONS[i].code)} />
        <ListItemText primary={RECITATIONS[i].name + star} />
      </MenuItem>
    );
  }

  return (
    <div className={classes.drawer}>
      <SwipeableDrawer
        disableBackdropTransition={isIOS}
        disableDiscovery={isIOS}
        variant={"temporary"}
        anchor={"right"}
        open={props.isOpen}
        onClose={props.handleCloseDrawer}
        onOpen={props.handleOpenDrawer}
        ModalProps={{
          keepMounted: true,
          BackdropProps: {
            classes: {
              root: classes.backdrop
            }
          }
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton aria-label="Close" title="Close" onClick={props.handleCloseDrawer} className={classes.topButton}>
            <ArrowBack color="secondary" />
          </IconButton>
          <IconButton aria-label="Close" title="Close" onClick={props.handleHelpButtonClicked} className={classes.topButton}>
            <HelpOutline color="secondary" />
          </IconButton>
        </div>
        <Divider />

        <div className={classes.drawerContent}>
          {/* Text Books */}
          <FormControl className={classes.selector}>
            <InputLabel>Text</InputLabel>
            <Select
              autoWidth={true}
              multiple
              value={props.selectedTextBooks}
              onChange={textBooksChanged}
              onClose={textBooksClosed}
              input={<Input />}
              renderValue={() => (
                <div className={classes.chips}>
                  {props.selectedTextBooks.map((translationCode: string) => (
                    <Chip
                      key={translationCode}
                      label={TEXTBOOKS.filter(book => book.translationCode === translationCode)[0].translationName}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
            >
              {buffer}
            </Select>
          </FormControl>

          {/* Recitations */}
          <FormControl className={classes.selector}>
            <InputLabel>Recitations</InputLabel>
            <Select
              autoWidth={true}
              multiple
              value={props.selectedRecitations}
              onChange={recitationsChanged}
              onClose={recitationsClosed}
              input={<Input />}
              renderValue={() => (
                <div className={classes.chips}>
                  {props.selectedRecitations.map((recitationCode: string) => (
                    <Chip
                      key={recitationCode}
                      label={RECITATIONS.filter(recitation => recitation.code === recitationCode)[0].name}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
            >
              {bufferRecitations}
            </Select>
          </FormControl>

          {/* Quran Font */}
          <FormControl className={classes.selector}>
            <InputLabel htmlFor="quran-font-selector">Quran Font</InputLabel>
            <Select
              autoWidth={true}
              value={props.selectedQuranFont}
              onChange={event => props.handleQuranFontChange(event.target.value)}
              inputProps={{
                name: "quran-font-selector",
                id: "quran-font-selector"
              }}
            >
              {quranFonts.map((item: QuranFont, index: number) => (
                <MenuItem value={item.name} key={"quran-font" + index.toString()}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Font Scaling */}
          <FormControl className={classes.selector}>
            <InputLabel htmlFor="font-scaling-selector">Text Scaling</InputLabel>
            <Select
              autoWidth={true}
              value={props.selectedFontScaling}
              onChange={event => props.handleFontScalingChange(event.target.value)}
              inputProps={{
                name: "font-scaling-selector",
                id: "font-scaling-selector"
              }}
            >
              {fontScalings.map((item: number) => (
                <MenuItem value={item} key={"font-scaling" + item.toString()}>
                  <span className={classes.narrowMenuItem}>{item + "%"}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Verse Repeat */}
          <FormControl className={classes.selector}>
            <InputLabel htmlFor="verse-repeat-selector">Verse Repeat</InputLabel>
            <Select
              autoWidth={true}
              value={props.verseRepeat}
              onChange={event => props.handleVerseRepeatChange(+event.target.value)}
              inputProps={{
                name: "verse-repeat-selector",
                id: "verse-repeat-selector"
              }}
            >
              {verseRepeats.map((item: number) => (
                <MenuItem value={item} key={"verse-repeat" + item.toString()}>
                  <span className={classes.narrowMenuItem}>{item}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Parallel Text Switch */}
          <FormControl className={classes.switch}>
            <FormControlLabel
              control={
                <Switch
                  checked={props.isParallelText}
                  onChange={event => {
                    props.handleParallelTextChange(event.target.checked);
                  }}
                  className={classes.switch}
                />
              }
              label="Parallel Text"
            />
          </FormControl>

          {/* Dark Theme Switch */}
          <FormControl className={classes.switch}>
            <FormControlLabel
              control={
                <Switch
                  checked={props.isDarkTheme}
                  onChange={event => {
                    props.handleDarkThemeChange(event.target.checked);
                  }}
                  className={classes.switch}
                />
              }
              label="Dark Theme"
            />
          </FormControl>

          {/* Auto Scroll Switch */}
          <FormControl className={classes.switch}>
            <FormControlLabel
              control={
                <Switch
                  checked={props.selectedAutoScroll}
                  onChange={event => {
                    props.handleAutoScrollChange(event.target.checked);
                  }}
                  className={classes.switch}
                />
              }
              label="Auto Scroll"
            />
          </FormControl>

          {/* Auto Next-Chapter Switch */}
          <FormControl className={classes.switch}>
            <FormControlLabel
              control={
                <Switch
                  checked={props.autoAdvanceChapter}
                  onChange={event => {
                    props.handleAutoAdvanceChapter(event.target.checked);
                  }}
                  color="secondary"
                />
              }
              label="Auto Next Chapter"
            />
          </FormControl>
        </div>
        <Box m={1} />
      </SwipeableDrawer>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        className={classes.snackbar}
        open={isSnackBarOpen}
        autoHideDuration={10000}
        onClose={handleSnackBarClose}
        message={snackBarMessage}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleSnackBarClose}></Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}

export default SettingsDrawer;
