import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import AudioComponent from "../components/AudioComponent";
import { ChapterVerseLocation } from "../models/ChapterVerseLocation";
import GetChapter from "../components/GetChapter";
import ProgressBar from "../components/ProgressBar";
import TopAppBar from "../components/TopAppBar";
import SearchDialog from "../components/SearchDialog";
import MetaTags from "../components/MetaTags";
import QuranText from "../components/QuranText";
import { graphql } from "gatsby";
import { Chapter } from "../models/Chapter";
import { VerseLocationForText } from "../models/VerseLocationForText";
import * as localStorage from "local-storage";
import ShareVersesDialog from "../components/ShareVersesDialog";
import Snackbar from "@material-ui/core/Snackbar";
import BottomAppBar from "../components/BottomAppBar";
import VersesDialog from "../components/VersesDialog";
import AboutDialog from "../components/AboutDialog";
import InfoDialog from "../components/InfoDialog";
import ContactDialog from "../components/ContactDialog";
import ChaptersMenu from "../components/ChaptersMenu";
import JuzMenu from "../components/JuzMenu";
import { ChapterInfo } from "../models/ChapterInfo";
import MenuPages from "../components/MenuPages";
import FontScalingMenu from "../components/FontScalingMenu";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { MainTheme } from "../constants/MainTheme";
import SettingsDrawer from "../components/SettingsDrawer";
import { textThemes } from "../constants/TextThemes";
import { JUZTABLE } from "../constants/JuzTable";
import { arraysEqual } from "../common/ArraysEqual";
import { computeUrlPath } from "../common/ComputeUrlPath";
import { gaEvent } from "../common/GaEvent";
import { getTextBooksFromUrl, getRecitationsFromUrl, getChapterNumberFromUrl, getVerseNumberFromUrl } from "../common/GetFromUrl";
import { websiteUrlFancy } from "../constants/BaseConstants";

let forceNewVerseNumberForText = 0; // To force scrolling to verse, even when verse number does not change
let isForceGetChapter = false;

let urlHash = "";
let bookmark: ChapterVerseLocation;
const maxWidthNarrow = "725px";
const maxWidthWide = "925px";
let timeStamp = 0;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function QuranChapter(props: any) {
  let chaptersInfo: ChapterInfo[];

  var verseRepeats = [];
  for (var i = 1; i <= 20; i++) {
    verseRepeats.push(i);
  }

  chaptersInfo = props.data.allQuranInfoJson.nodes;
  const defaultTextBooks = ["ar-allah", "en-itaniv2a"];
  const withStar = ["ar-allah", "en-itaniv2a", "alafasy-64", "iv2-pol-bri-a"];
  const defaultSearchTextBooks = ["ar-allah"];
  const defaultRecitations = ["alafasy-64"];
  const defaultIsAutoScroll = true;
  const defaultIsDarkTheme = false;
  const defaultIsParallelText = false;
  const defaultIsAutoNextChapter = true;
  const defaultFontScaling = 100;
  const defaultVerseRepeat = 1;
  const defaultChapter: Chapter = props.data.allTranslationJson.nodes[0];
  const defaultQuranFont = "Hafs";

  // state to save in local storage
  const [textBooks, setTextBooks] = React.useState(defaultTextBooks);
  const [searchTextBooks, setSearchTextBooks] = React.useState(defaultSearchTextBooks);
  const [recitations, setRecitations] = React.useState(defaultRecitations);
  const [recitationPointer, setRecitationPointer] = React.useState(0);
  const [fontScaling, setFontScaling] = React.useState(defaultFontScaling);
  const [quranFont, setQuranFont] = React.useState(defaultQuranFont);
  const [verseRepeat, setVerseRepeat] = React.useState(defaultVerseRepeat);
  const [verseRepeatCounter, setVerseRepeatCounter] = React.useState(defaultVerseRepeat);
  const [isAutoScroll, setIsAutoScroll] = React.useState(defaultIsAutoScroll);
  const [isDarkTheme, setIsDarkTheme] = React.useState(defaultIsDarkTheme);
  const [isParallelText, setIsParallelText] = React.useState(defaultIsParallelText);
  const [isAutoNextChapter, setIsAutoNextChapter] = React.useState(defaultIsAutoNextChapter);
  //
  const [isAudioPlaying, setIsAudioPlaying] = React.useState(false);
  const [isAudioPlaying2, setIsAudioPlaying2] = React.useState(true);
  const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = React.useState(false);
  const [chapter, setChapter] = React.useState(defaultChapter);
  const [verseNumberForAudio, setVerseNumberForAudio] = React.useState(chapter.verseNumbers[0]);
  const [isProgressBarVisible, setIsProgressBarVisible] = React.useState(false);
  const [isSearchDialogOpen, setIsSearchDialogOpen] = React.useState(false);

  const defaultVerseLocationForText = new VerseLocationForText(defaultChapter.verseNumbers[0], false, false, 0);
  const defaultChapterVerseLocation = new ChapterVerseLocation(defaultChapter.chapterNumber, defaultVerseLocationForText);

  const [verseLocationForText, setVerseLocationForText] = React.useState(defaultVerseLocationForText);
  const [chapterVerseLocation, setChapterVerseLocation] = React.useState(defaultChapterVerseLocation);

  const [isShareVersesDialogOpen, setIsShareVersesDialogOpen] = React.useState(false);
  const [isVerseDialogOpen, setIsVerseDialogOpen] = React.useState(false);

  const [anchorChapterMenu, setAnchorChapterMenu] = React.useState(null);
  const [anchorJuzMenu, setAnchorJuzMenu] = React.useState(null);
  const [anchorPagesMenu, setAnchorPagesMenu] = React.useState(null);
  const [anchorFontScalingMenu, setAnchorFontScalingMenu] = React.useState(null);

  const [isAboutDialogOpen, setIsAboutDialogOpen] = React.useState(false);
  const [isContactDialogOpen, setIsContactDialogOpen] = React.useState(false);
  const [isHelpDialogOpen, setIsHelpDialogOpen] = React.useState(false);
  const [isShare, setIsShare] = React.useState(false);

  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const useStyles = makeStyles(theme => ({
    contentNarrow: {
      maxWidth: maxWidthNarrow,
      margin: "auto"
    },
    contentWide: {
      maxWidth: maxWidthWide,
      margin: "auto"
    },
    root: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: isDarkTheme ? textThemes[1].background : textThemes[0].background
    },
    main: {
      margin: 0,
      padding: 0
    },
    toolbar: theme.mixins.toolbar,
    fontUthmani: {
      fontFamily: "Uthmani"
    },
    fontIndopak: {
      fontFamily: "Indopak"
    },
    fontScheherazade: {
      fontFamily: "Scheherazade"
    },
    fontSystem: {
      fontFamily: "System"
    },
    bookmarkSnackbar: {
      bottom: "60px"
    }
  }));

  const classes = useStyles(props);

  if (typeof document !== "undefined") {
    document.body.style.backgroundColor = isDarkTheme ? textThemes[1].background : textThemes[0].background;
  }

  if (typeof window !== "undefined") {
    urlHash = window.location.hash;
  }

  function handleTextBooksChange(textBooks: string[]) {
    timeStamp = performance.now();
    setIsProgressBarVisible(true);
    setTextBooks(textBooks);
    isForceGetChapter = true;
    const chapterVerseLocation = new ChapterVerseLocation(chapter.chapterNumber, verseLocationForText); // keep same location
    setChapterVerseLocation(chapterVerseLocation);
  }

  function gotoLocation(gotoLocation: ChapterVerseLocation) {
    setIsAudioPlaying2(false);
    setIsSearchDialogOpen(false);
    setRecitationPointer(0);
    if (chapter.chapterNumber === gotoLocation.chapterNumber) {
      setVerseLocationForText(gotoLocation.verseLocationForText);
    } else {
      timeStamp = performance.now();
      setIsProgressBarVisible(true);
      setChapterVerseLocation(gotoLocation);
    }
  }

  function handleNewChapter(newChNum: number) {
    setRecitationPointer(0);
    setAnchorChapterMenu(false);
    setIsAudioPlaying2(false);
    const verseLocationForText = new VerseLocationForText(
      newChNum === 1 || newChNum === 9 ? 1 : 0,
      true,
      false,
      ++forceNewVerseNumberForText
    );
    if (newChNum === chapter.chapterNumber) {
      setVerseLocationForText(verseLocationForText);
    } else {
      timeStamp = performance.now();
      setIsProgressBarVisible(true);
      const chapterVerseLocation = new ChapterVerseLocation(newChNum, verseLocationForText);
      setChapterVerseLocation(chapterVerseLocation);
    }
  }

  React.useEffect(() => {
    bookmark = localStorage.get<ChapterVerseLocation>("BOOKMARK") != null ? localStorage.get<ChapterVerseLocation>("BOOKMARK") : null;
    // set state from local storage
    setTextBooks(localStorage.get<string[]>("TEXT-BOOKS") != null ? localStorage.get<string[]>("TEXT-BOOKS") : defaultTextBooks);
    setSearchTextBooks(
      localStorage.get<string[]>("SEARCH-TEXT-BOOKS") != null ? localStorage.get<string[]>("SEARCH-TEXT-BOOKS") : defaultSearchTextBooks
    );
    setRecitations(localStorage.get<string[]>("RECITATIONS") != null ? localStorage.get<string[]>("RECITATIONS") : defaultRecitations);
    setFontScaling(localStorage.get<number>("FONT-SCALING") != null ? localStorage.get<number>("FONT-SCALING") : defaultFontScaling);
    setQuranFont(localStorage.get<string>("QURAN-FONT") != null ? localStorage.get<string>("QURAN-FONT") : defaultQuranFont);
    setVerseRepeat(localStorage.get<number>("VERSE-REPEAT") != null ? localStorage.get<number>("VERSE-REPEAT") : defaultVerseRepeat);
    setVerseRepeatCounter(localStorage.get<number>("VERSE-REPEAT") != null ? localStorage.get<number>("VERSE-REPEAT") : defaultVerseRepeat);
    setIsAutoScroll(
      localStorage.get<boolean>("IS-AUTO-SCROLL") != null ? localStorage.get<boolean>("IS-AUTO-SCROLL") : defaultIsAutoScroll
    );
    setIsDarkTheme(localStorage.get<boolean>("IS-DARK-THEME") != null ? localStorage.get<boolean>("IS-DARK-THEME") : defaultIsDarkTheme);
    setIsParallelText(
      localStorage.get<boolean>("IS-PARALLEL-TEXT") != null ? localStorage.get<boolean>("IS-PARALLEL-TEXT") : defaultIsParallelText
    );
    setIsAutoNextChapter(
      localStorage.get<boolean>("IS-AUTO-NEXT-CHAPTER") != null
        ? localStorage.get<boolean>("IS-AUTO-NEXT-CHAPTER")
        : defaultIsAutoNextChapter
    );

    // verseNumber
    let verseNumber = getVerseNumberFromUrl(urlHash);

    // chapterNumber
    let chapterNumber = getChapterNumberFromUrl(urlHash);

    // textBooks
    let isLoadNewText = false;
    let newTextBooks = getTextBooksFromUrl(urlHash);
    if (newTextBooks.length !== 0) {
      setTextBooks(newTextBooks);
      isLoadNewText = true;
    } else if (
      localStorage.get<string[]>("TEXT-BOOKS") != null &&
      !arraysEqual(localStorage.get<string[]>("TEXT-BOOKS"), defaultTextBooks)
    ) {
      isLoadNewText = true;
      newTextBooks = localStorage.get<string[]>("TEXT-BOOKS");
    }

    // Recitations
    let recitationsFromUrl = getRecitationsFromUrl(urlHash);
    if (recitationsFromUrl.length !== 0) {
      setRecitations(recitationsFromUrl);
    }

    if (isLoadNewText) {
      if (verseNumber === -1 && chapterNumber === -1) {
        isForceGetChapter = true;
        setIsProgressBarVisible(true); // because handleNewChapter displays progress-bar only if new Chapter
        handleNewChapter(1); // To top of Chapter 1
      } else if (chapterNumber === -1 && verseNumber != -1) {
        isForceGetChapter = true;
        const chapterVerseLocation = new ChapterVerseLocation(1, new VerseLocationForText(verseNumber, true, true, 0)); // keep same location
        setChapterVerseLocation(chapterVerseLocation);
      } else if (chapterNumber != -1 && verseNumber != -1) {
        const gotoLocationLocal = new ChapterVerseLocation(chapterNumber, new VerseLocationForText(verseNumber, true, true, 0)); // to location
        gotoLocation(gotoLocationLocal);
      } else if (chapterNumber != -1 && verseNumber == -1) {
        handleNewChapter(chapterNumber); // top of new chapter
      }
    } else {
      if (chapterNumber != -1 && verseNumber != -1) {
        const chapterVerseLocation = new ChapterVerseLocation(chapterNumber, new VerseLocationForText(verseNumber, true, true, 0));
        setChapterVerseLocation(chapterVerseLocation);
      } else if (chapterNumber != -1 && verseNumber == -1) {
        handleNewChapter(chapterNumber); // top of new chapter
      } else if (chapterNumber == -1 && verseNumber != -1) {
        const verseLocation = new VerseLocationForText(verseNumber, true, true, 1);
        setVerseLocationForText(verseLocation);
      }
    }
  }, []);

  // below, tried to make browser back/forward buttons works, but things did not work
  /*   React.useEffect(() => {
    window.onhashchange = function() {
      urlHash = window.location.hash;
      let chapterNumber = getChapterNumberFromUrl(urlHash);
      if (chapterNumber == -1) {
        chapterNumber = 1;
      }
      handleNewChapter(chapterNumber);
    };
  }); */

  React.useEffect(() => {
    localStorage.set<string[]>("TEXT-BOOKS", textBooks);
    localStorage.set<string[]>("SEARCH-TEXT-BOOKS", searchTextBooks);
    localStorage.set<string[]>("RECITATIONS", recitations);
    localStorage.set<number>("FONT-SCALING", fontScaling);
    localStorage.set<string>("QURAN-FONT", quranFont);
    localStorage.set<number>("VERSE-REPEAT", verseRepeat);
    localStorage.set<boolean>("IS-DARK-THEME", isDarkTheme);
    localStorage.set<boolean>("IS-PARALLEL-TEXT", isParallelText);
    localStorage.set<boolean>("IS-AUTO-SCROLL", isAutoScroll);
    localStorage.set<boolean>("IS-AUTO-NEXT-CHAPTER", isAutoNextChapter);
  }, [
    textBooks,
    searchTextBooks,
    recitations,
    quranFont,
    fontScaling,
    verseRepeat,
    isParallelText,
    isDarkTheme,
    isAutoScroll,
    isAutoNextChapter
  ]);

  function handleVerseRepeatInput(verseRepeat: NumberConstructor) {
    setVerseRepeat(verseRepeat);
    setVerseRepeatCounter(verseRepeat);
  }

  function handlePlayPause() {
    if (!isAudioPlaying) {
      // We are really checking if audio is playing - because the button toggles the state
      const verseLocation = new VerseLocationForText(verseLocationForText.verseNumber, true, true, ++forceNewVerseNumberForText);
      setVerseLocationForText(verseLocation);
      gaEvent("Play/Pause Clicked");
    }
    setIsAudioPlaying(!isAudioPlaying);
    setIsAudioPlaying2(true);
  }

  function handleNextButtonClicked() {
    handleNewChapter(chapter.chapterNumber < 114 ? chapter.chapterNumber + 1 : 1);
    gaEvent("Next Button Clicked");
  }

  function handlePrevButtonClicked() {
    handleNewChapter(chapter.chapterNumber > 1 ? chapter.chapterNumber - 1 : 114);
    gaEvent("Prev Button Clicked");
  }

  function getNextChapter() {
    handleNewChapter(chapter.chapterNumber < 114 ? chapter.chapterNumber + 1 : 1);
  }

  function handleVerseClicked(vNumber: number) {
    setIsAudioPlaying2(false);
    if (vNumber === verseLocationForText.verseNumber) {
      setVerseLocationForText(new VerseLocationForText(vNumber, false, false, ++forceNewVerseNumberForText));
    } else {
      setVerseLocationForText(new VerseLocationForText(vNumber, false, false, 0));
    }
    gaEvent("Verse Clicked");
  }

  function handleVersesDialogItemClick(index: number) {
    setIsAudioPlaying2(false);
    setIsVerseDialogOpen(false);
    if (index === verseLocationForText.verseNumber) {
      setVerseLocationForText(new VerseLocationForText(index, true, true, ++forceNewVerseNumberForText));
    } else {
      setVerseLocationForText(new VerseLocationForText(index, true, true, 0));
    }
    gaEvent("Verses Dialog, Verse Clicked");
  }

  function handleAudioEnded() {
    setRecitationPointer(0);
    let localCounter = verseRepeatCounter - 1;
    if (localCounter <= 0) {
      setVerseRepeatCounter(verseRepeat);
      if (verseNumberForAudio < chapter.verseNumbers[chapter.verses[0].length - 1]) {
        setVerseLocationForText(new VerseLocationForText(verseNumberForAudio + 1, isAutoScroll, true, 0));
        setIsAudioPlaying2(false);
      } else if (isAutoNextChapter === true) {
        getNextChapter();
      } else if (isAutoNextChapter === !true) {
        setIsAudioPlaying2(false);
      }
    } else {
      setVerseRepeatCounter(localCounter);
    }
    gaEvent(`Recitations: ${recitations.join(", ")}`);
    gaEvent(`Verse Repeat: ${verseRepeat}`);
    gaEvent(`Auto Scroll: ${isAutoScroll}`);
    gaEvent(`Auto Next Chapter: ${isAutoNextChapter}`);
  }

  function handleChapterRenderingDone() {
    let seconds = 0.25 * Math.round((performance.now() - timeStamp) / 250);
    gaEvent(`${seconds} sec to load & render text.`);

    setRecitationPointer(0);
    setIsProgressBarVisible(false);
    setVerseLocationForText(chapterVerseLocation.verseLocationForText);
    const newUrl = computeUrlPath(
      verseLocationForText.verseNumber,
      chapter.chapterNumber,
      defaultTextBooks,
      textBooks,
      defaultRecitations,
      recitations
    );
    // below, tried to make browser back/forward buttons works, but things did not work
    /*
    if (chapter.chapterNumber == 1 && getChapterNumberFromUrl(window.location.hash) == -1) {
      window.history.replaceState(null, null, newUrl);
    } else if (chapter.chapterNumber != getChapterNumberFromUrl(window.location.hash)) {
      window.history.pushState(null, null, newUrl);
    } else {
      window.history.replaceState(null, null, newUrl);
    } */
    window.history.replaceState(null, null, newUrl);

    gaEvent(`Quran Font: ${quranFont}`);
    gaEvent(`Font Scaling: ${fontScaling}`);
    gaEvent(`Parallel Text: ${isParallelText}`);
    gaEvent(`Dark Theme: ${isDarkTheme}`);
  }

  function handleVerseScrollingDone(verseNumber: number) {
    setTimeout(() => {
      setIsAudioPlaying2(true); // needed because setIsAudioPlaying2 can change very fast if there is no actual scrolling
    }, 100);
    setVerseNumberForAudio(verseNumber);
    window.history.replaceState(
      null,
      null,
      computeUrlPath(verseLocationForText.verseNumber, chapter.chapterNumber, defaultTextBooks, textBooks, defaultRecitations, recitations)
    );
  }

  function handleNewChapterLoaded(newChapter: Chapter) {
    isForceGetChapter = false;
    setRecitationPointer(0);
    setChapter(newChapter);
    setVerseLocationForText(chapterVerseLocation.verseLocationForText);
  }

  function handleBookmarkClicked() {
    setAnchorPagesMenu(null);
    const verseLocation = new VerseLocationForText(verseNumberForAudio, true, true, 0);
    bookmark = new ChapterVerseLocation(chapter.chapterNumber, verseLocation);
    localStorage.set<ChapterVerseLocation>("BOOKMARK", bookmark);
    setSnackbarMessage("Verse " + chapter.chapterNumber + ":" + verseLocationForText.verseNumber + " bookmarked");
    setIsSnackbarOpen(true);
    gaEvent("Verse Bookmarked");
  }

  function handleJuzMenuCLicked() {
    setAnchorPagesMenu(null);
    setAnchorJuzMenu(anchorPagesMenu);
  }

  function handleGotoBookmarkClicked() {
    setAnchorPagesMenu(null);
    if (bookmark != null) {
      gotoLocation(bookmark);
      gaEvent("Go To Bookmark");
    }
  }

  function gotoNewUrl(url: string) {
    setAnchorPagesMenu(null);
    window.location.href = url;
  }

  const handleCloseSnackbar = () => {
    setIsSnackbarOpen(false);
  };

  function handleSearchTextBooksChange(textBooks: string[]) {
    setSearchTextBooks(textBooks);
  }

  function handleRecitationsChange(recitations: string[]) {
    setRecitations(recitations);
    setRecitationPointer(recitations.length - 1);
    window.history.replaceState(
      null,
      null,
      computeUrlPath(verseNumberForAudio, chapter.chapterNumber, defaultTextBooks, textBooks, defaultRecitations, recitations)
    );
  }

  function handleGotoJuz(juzIndex: number) {
    setAnchorJuzMenu(null);
    if (JUZTABLE[juzIndex][0] != chapter.chapterNumber) {
      setIsProgressBarVisible(true);
    }
    const gotoLocationLocal = new ChapterVerseLocation(
      JUZTABLE[juzIndex][0],
      new VerseLocationForText(JUZTABLE[juzIndex][1], true, true, 0)
    );
    gotoLocation(gotoLocationLocal);
    gaEvent("Go to Juz Clicked");
  }

  function handleShareClicked() {
    setIsShare(true);
    setIsShareVersesDialogOpen(true);
  }

  function handleCopyClicked() {
    setIsShare(false);
    setIsShareVersesDialogOpen(true);
  }

  function handleShareVersesDialog(message: string) {
    setIsShareVersesDialogOpen(false);
    if (message) {
      setSnackbarMessage(message);
      setIsSnackbarOpen(true);
    }
  }

  return (
    <MuiThemeProvider theme={MainTheme}>
      <div className={classes.root}>
        <main className={classes.main}>
          <div className={classes.toolbar} />
          <article className={isParallelText ? classes.contentWide : classes.contentNarrow}>
            <FontScalingMenu
              anchor={anchorFontScalingMenu}
              fontScaling={fontScaling}
              handleMenuClose={() => setAnchorFontScalingMenu(null)}
              fontScalingItemClicked={(fontScaling: number) => {
                setFontScaling(fontScaling);
                gaEvent("Font Scaled from Font-Scaling Menu");
                // setAnchorFontScalingMenu(null);
              }}
            />
            <QuranText
              chapter={chapter}
              fontScaling={fontScaling}
              quranFont={quranFont}
              isDarkTheme={isDarkTheme}
              isParallelText={isParallelText}
              verseClicked={handleVerseClicked}
              chapterRenderingDone={() => handleChapterRenderingDone()}
              verseScrollingDone={(verseNumber: number) => handleVerseScrollingDone(verseNumber)}
              verseLocation={verseLocationForText}
            />
          </article>

          {recitations.length > 0 && (
            <AudioComponent
              isAudioPlaying={isAudioPlaying}
              isAudioPlaying2={isAudioPlaying2}
              chapter={chapter}
              verseNumber={verseNumberForAudio}
              recitations={recitations}
              recitationPointer={recitationPointer}
              handleRecitationPointerChange={(pointer: number) => setRecitationPointer(pointer)}
              audioEnded={() => handleAudioEnded()}
            />
          )}

          {(chapterVerseLocation.chapterNumber !== chapter.chapterNumber || isForceGetChapter) && (
            <GetChapter
              chaptersInfo={chaptersInfo}
              textBooks={textBooks}
              chapterNumber={chapterVerseLocation.chapterNumber}
              newChapterLoaded={handleNewChapterLoaded}
            />
          )}
          <Box p={7} />
        </main>

        <SettingsDrawer
          selectedTextBooks={textBooks}
          defaultTextBooks={defaultTextBooks}
          handleTextBooksChange={handleTextBooksChange}
          selectedRecitations={recitations}
          defaultRecitations={defaultRecitations}
          withStar={withStar}
          handleRecitationsChange={handleRecitationsChange}
          isOpen={isSettingsDrawerOpen}
          handleCloseDrawer={() => setIsSettingsDrawerOpen(false)}
          handleOpenDrawer={() => setIsSettingsDrawerOpen(true)}
          selectedAutoScroll={isAutoScroll}
          handleAutoScrollChange={(autoScroll: boolean) => setIsAutoScroll(autoScroll)}
          isDarkTheme={isDarkTheme}
          handleDarkThemeChange={(darkTheme: boolean) => setIsDarkTheme(darkTheme)}
          isParallelText={isParallelText}
          handleParallelTextChange={(parallelText: boolean) => setIsParallelText(parallelText)}
          autoAdvanceChapter={isAutoNextChapter}
          handleAutoAdvanceChapter={(autoAdvanceChapter: boolean) => setIsAutoNextChapter(autoAdvanceChapter)}
          selectedQuranFont={quranFont}
          handleQuranFontChange={(quranFont: string) => setQuranFont(quranFont)}
          selectedFontScaling={fontScaling}
          handleFontScalingChange={(fontScaling: number) => setFontScaling(fontScaling)}
          handleVerseRepeatChange={handleVerseRepeatInput}
          verseRepeat={verseRepeat}
          handleHelpButtonClicked={() => setIsHelpDialogOpen(true)}
        />

        <MenuPages
          anchor={anchorPagesMenu}
          isShowBookmarkEntries={true}
          chapterNumber={props.chapterNumber}
          aboutClicked={() => {
            setIsAboutDialogOpen(true);
            setAnchorPagesMenu(null);
            gaEvent("About Clicked");
          }}
          contactClicked={() => {
            setIsContactDialogOpen(true);
            setAnchorPagesMenu(null);
            gaEvent("Contact Clicked");
          }}
          bookmarkClicked={handleBookmarkClicked}
          gotoBookmarkClicked={handleGotoBookmarkClicked}
          handleMenuClose={() => setAnchorPagesMenu(null)}
          handleGoToURL={(url: string) => gotoNewUrl(url)}
          juzMenuClicked={handleJuzMenuCLicked}
        />

        <AboutDialog isOpen={isAboutDialogOpen} handleCloseDialog={() => setIsAboutDialogOpen(false)} />

        <InfoDialog
          paragraphs={[
            "• Select one or more Texts.",
            "• Select one or more Recitations.",
            "• When displaying parallel Text, only the first two selected Texts are displayed.",
            "• Selecting multiple Texts can reduce the responsiveness of the App, especially with long suras and mobile devices."
          ]}
          title1={websiteUrlFancy}
          title2="Quran with Translations and Recitations"
          isOpen={isHelpDialogOpen}
          handleCloseDialog={() => setIsHelpDialogOpen(false)}
        />

        <ContactDialog isOpen={isContactDialogOpen} handleCloseDialog={() => setIsContactDialogOpen(false)} />

        {/* Search */}
        <SearchDialog
          fontScaling={fontScaling}
          quranFont={quranFont}
          isOpen={isSearchDialogOpen}
          handleClose={() => setIsSearchDialogOpen(false)}
          searchVerseClick={gotoLocation}
          chaptersInfo={chaptersInfo}
          selectedSearchTextBooks={searchTextBooks}
          defaultTextBooks={defaultSearchTextBooks}
          handleSearchTextBooksChange={handleSearchTextBooksChange}
          withStar={withStar}
        />

        <ProgressBar isVisible={isProgressBarVisible} />
        <MetaTags />
        <VersesDialog
          isOpen={isVerseDialogOpen}
          chapter={chapter}
          verseNumber={verseNumberForAudio}
          verseNumberClicked={(verseNumber: number) => handleVersesDialogItemClick(verseNumber)}
          handleCloseDialog={() => setIsVerseDialogOpen(false)}
        />
        <ChaptersMenu
          anchor={anchorChapterMenu}
          chaptersInfo={chaptersInfo}
          chapterClicked={(chapterIndex: number) => handleNewChapter(chapterIndex + 1)}
          handleMenuClose={() => setAnchorChapterMenu(null)}
          currentChapterNumber={chapter.chapterNumber}
        />
        <JuzMenu
          anchor={anchorJuzMenu}
          juzClicked={(juzIndex: number) => handleGotoJuz(juzIndex)}
          handleMenuClose={() => setAnchorJuzMenu(null)}
          chapterNumber={chapter.chapterNumber}
          verseNumber={verseLocationForText.verseNumber}
        />
        <TopAppBar
          chapter={chapter}
          onClickMenuButton={(event: React.MouseEvent<HTMLElement>) => {
            setAnchorPagesMenu(event.currentTarget);
            gaEvent("Menu Button Clicked");
          }}
          onClickVerticalSwapButton={() => setIsVerseDialogOpen(true)}
          onClickSettingsButton={() => {
            setIsSettingsDrawerOpen(true);
            gaEvent("Settings Button Clicked");
          }}
          onClickTitleBlock={(event: React.MouseEvent<HTMLElement>) => {
            setAnchorChapterMenu(event.currentTarget);
            gaEvent("Titles Block Clicked");
          }}
          onClickTextSizeButton={(event: React.MouseEvent<HTMLElement>) => setAnchorFontScalingMenu(event.currentTarget)}
        />
        <BottomAppBar
          isAudioPlaying={isAudioPlaying}
          playPauseClicked={handlePlayPause}
          nextClicked={handleNextButtonClicked}
          prevClicked={handlePrevButtonClicked}
          searchClicked={() => setIsSearchDialogOpen(true)}
          shareClicked={handleShareClicked}
          copyClicked={handleCopyClicked}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          className={classes.bookmarkSnackbar}
          open={isSnackbarOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
        />
        {/* Share Verses Dialog */}
        <ShareVersesDialog
          currentVerseNumber={verseLocationForText.verseNumber}
          chapter={chapter}
          isOpen={isShareVersesDialogOpen}
          handleCloseDialog={(message: string) => handleShareVersesDialog(message)}
          isShare={isShare}
          textBooks={textBooks}
          defaultTextBooks={defaultTextBooks}
          recitations={recitations}
          defaultRecitations={defaultRecitations}
        />
      </div>
    </MuiThemeProvider>
  );
}

QuranChapter.propTypes = {
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object
};

export default QuranChapter;

export const quranQuery = graphql`
  query QuranInfoJson2($slug: Int! = 1) {
    allTranslationJson(filter: { chapterNumber: { eq: $slug } }) {
      nodes {
        nameEnglish
        nameTrans
        nameArabic
        chapterNumber
        verses
        verseNumbers
        textBooks
      }
    }
    allQuranInfoJson {
      nodes {
        nVerses
        nameEnglish
        nameTrans
        nameArabic
      }
    }
  }
`;
