import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Bookmark from "@material-ui/icons/Bookmark";
import Book from "@material-ui/icons/Book";

interface MenuPagesProps {
  anchor: HTMLElement;
  isShowBookmarkEntries: boolean;
  chapterNumber: number;
  bookmarkClicked: () => void;
  aboutClicked: () => void;
  contactClicked: () => void;
  gotoBookmarkClicked: () => void;
  juzMenuClicked: () => void;
  handleMenuClose: Function;
  handleGoToURL: Function;
}

const arePropsEqual = (prevProps: MenuPagesProps, nextProps: MenuPagesProps) => {
  const areEqual =
    prevProps.anchor === nextProps.anchor &&
    prevProps.isShowBookmarkEntries === nextProps.isShowBookmarkEntries &&
    prevProps.chapterNumber === nextProps.chapterNumber;
  return areEqual;
};

function MenuPages(props: MenuPagesProps) {
  return (
    <Menu id="pages-menu" anchorEl={props.anchor} open={Boolean(props.anchor)} onClose={() => props.handleMenuClose(null)}>
      <MenuItem onClick={props.aboutClicked}>About</MenuItem>
      <MenuItem onClick={props.contactClicked}>Contact</MenuItem>
      <Divider />
      <MenuItem onClick={() => props.handleGoToURL("https://www.quranwow.com")}>Classic Version</MenuItem>
      {props.isShowBookmarkEntries && (
        <div>
          <Divider />
          <MenuItem onClick={props.bookmarkClicked}>
            {" "}
            <ListItemIcon style={{ float: "right" }}>
              <Book fontSize="small" />
            </ListItemIcon>{" "}
            Bookmark Verse
          </MenuItem>
          <MenuItem onClick={props.gotoBookmarkClicked}>
            {" "}
            <ListItemIcon style={{ float: "right" }}>
              <Bookmark fontSize="small" />
            </ListItemIcon>{" "}
            Go To Bookmark
          </MenuItem>
          <Divider />
          <MenuItem onClick={props.juzMenuClicked}>Juz - Para</MenuItem>
        </div>
      )}
    </Menu>
  );
}
export default React.memo(MenuPages, arePropsEqual);
