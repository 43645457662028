import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { arraysEqual } from "../common/ArraysEqual";

interface InfoDialogProps {
  isOpen: boolean;
  handleCloseDialog: (event: React.MouseEvent<HTMLElement>) => void;
  paragraphs: string[];
  title1: string;
  title2: string;
}

const arePropsEqual = (prevProps: InfoDialogProps, nextProps: InfoDialogProps) => {
  const areEqual =
    prevProps.isOpen === nextProps.isOpen &&
    arraysEqual(prevProps.paragraphs, nextProps.paragraphs) &&
    prevProps.title1 === nextProps.title2;
  return areEqual;
};

const useStyles = makeStyles({
  title: {
    margin: "5px 0 0px 10px",
    padding: 0
  },
  content: {
    paddingTop: "15px"
  },
  paragraph: {
    margin: "0px 0 10px 0",
    lineHeight: 1.4
  }
});

function InfoDialog(props: InfoDialogProps) {
  const classes = useStyles(props);

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleCloseDialog}
      aria-labelledby="about-dialog-title"
      aria-describedby="about-dialog-description"
    >
      <DialogTitle className={classes.title}>
        <ListItem alignItems="center">
          <ListItemAvatar>
            <Avatar alt="Quran with Translations and Recitations" src="/images/quran-multilingual-app-icon-128px.png" />
          </ListItemAvatar>
          <ListItemText primary={props.title1} secondary={props.title2} />
        </ListItem>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        {props.paragraphs.map((paragraph: string, index: number) => (
          <Typography key={"general-dialog-" + index.toString()} className={classes.paragraph}>
            {paragraph}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCloseDialog} color="secondary">
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(InfoDialog, arePropsEqual);
