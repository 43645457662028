import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { blueUrlColor } from "../constants/BaseConstants";
import { websiteUrlFancy } from "../constants/BaseConstants";

interface AboutDialogProps {
  isOpen: boolean;
  handleCloseDialog: (event: React.MouseEvent<HTMLElement>) => void;
}

const useStyles = makeStyles({
  title: {
    margin: "5px 0 0px 10px",
    padding: 0
  },
  content: {
    paddingTop: "15px"
  },
  paragraph: {
    margin: "0px 0 10px 0"
  },
  link: {
    textDecoration: "none",
    color: blueUrlColor
  },
  firstParagraph: {
    fontWeight: "bold"
  },
  lastParagraph: {
    fontStyle: "italic"
  }
});

export default function AboutDialog(props: AboutDialogProps) {
  const classes = useStyles(props);

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleCloseDialog}
      aria-labelledby="about-dialog-title"
      aria-describedby="about-dialog-description"
    >
      <DialogTitle className={classes.title}>
        <ListItem alignItems="center">
          <ListItemAvatar>
            <Avatar alt="Quran with Translations and Recitations" src="/images/quran-multilingual-app-icon-128px.png" />
          </ListItemAvatar>
          <ListItemText primary={websiteUrlFancy} secondary="Quran with Translations and Recitations" />
        </ListItem>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <Typography className={classes.paragraph + " " + classes.firstParagraph}>Quran Multilingual Online</Typography>
        <Typography className={classes.paragraph}>
          Default English translation from:{" "}
          <a className={classes.link} href="https://www.helloquran.com">
            HelloQuran.com
          </a>
        </Typography>
        <Typography className={classes.paragraph}>
          Quran Arabic text from:{" "}
          <a className={classes.link} href="http://tanzil.net">
            Tanzil.net
          </a>
        </Typography>
        <Typography className={classes.paragraph}>
          App development by:{" "}
          <a className={classes.link} href="mailto:talal6558@gmail.com">
            Talal Itani
          </a>
        </Typography>
        <Typography className={classes.paragraph + " " + classes.lastParagraph}>
          Please share QuranWow.com with your friends and family.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCloseDialog} color="secondary">
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
